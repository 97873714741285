import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { SystemUser } from '../models/system-user.model';
import { ProfileType } from '../models/profile.model';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
export const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getUserByEmail(email: string): Observable<SystemUser> {
    return this.get<SystemUser>(`ge-api/auth/user/email/?email=${email.replace('+', '%2B')}`);
  }

  getUserById(id: number): Observable<SystemUser> {
    return this.post<SystemUser[]>(`ge-api/auth/user/ids`, [id])
      .pipe(map(result => {
        return result[0];
      }))
    }

  getProfile() : Observable<ProfileType> {
      return this.get<ProfileType>(GRAPH_ENDPOINT);
  }

  getUserInfo(userIds: number[]): Observable<SystemUser[]> {
      if (userIds == null || userIds.length == 0) {
        return of([]);
      }

      let uniqueValues = userIds.filter((n, i) => userIds.indexOf(n) === i);
      return this.post<SystemUser[]>('ge-api/auth/user/ids', uniqueValues);
  }

  getUsersWithHarvests(): Observable<SystemUser[]> {
    return this.getMany<number>('harvest-history-api/documentreview/user')
      .pipe(mergeMap(users => this.getUserInfo(users)));
  }
}
