import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { AddressDetailsComponent } from 'src/app/harvests/address-details/address-details.component';
import { AgentAgencyComponent } from 'src/app/harvests/agent-agency/agent-agency.component';
import { SBIsListComponent } from 'src/app/harvests/sbis-list/sbis-list.component';
import { HarvestOwnerDetailsComponent } from 'src/app/harvests/harvest-owner-details/harvest-owner-details.component';
import { Address } from 'src/app/models/address.model';
import { UserDocument } from 'src/app/models/grower-document.model';
import { SystemUser } from 'src/app/models/system-user.model';
import { SBI } from 'src/app/models/sbi.model';
import { Guid } from 'src/app/models/guid';
import { HarvestOwner, OwnerType } from 'src/app/models/harvest-owner.model';
import { DocumentService } from 'src/app/services/document.service';
import { DocumentPropertiesComponent } from '../document-properties/document-properties.component';
import { Agent } from 'src/app/models/agency.model';
import { AgencyAgentService } from 'src/app/services/agency-agent.service';
import { processReview } from '../../models/process-review.model';
import { ReviewService } from '../../services/review.service';
import { createMessageContent } from 'src/app/utility';
import { MessageContent } from 'src/app/messages/messages.component';

@Component({
  selector: 'hh-process-document',
  templateUrl: './process-document.component.html',
  styleUrls: ['./process-document.component.scss']
})
export class ProcessDocumentComponent implements OnInit {
  @ViewChild(MatAccordion) accordion?: MatAccordion;
  @ViewChildren(MatExpansionPanel) panels?: QueryList<MatExpansionPanel>;

  @ViewChild(DocumentPropertiesComponent) properties?: DocumentPropertiesComponent;
  @ViewChild(AgentAgencyComponent) agentAgency?: AgentAgencyComponent;
  @ViewChild(HarvestOwnerDetailsComponent) harvestOwner?: HarvestOwnerDetailsComponent;
  @ViewChildren(AddressDetailsComponent) addresses?: QueryList<AddressDetailsComponent>;
  @ViewChild(SBIsListComponent) sbisList?: SBIsListComponent;

  sameAsPhysical = true;

  harvestOwnerType = OwnerType.Individual;
  owner?: HarvestOwner;
  sbis: SBI[] = [];
  saving = false;
  document!: UserDocument

  messageTitle: string = '';
  errorMessages: string[] = [];

  documentId: string = '';
  user!: SystemUser;
  savedAgent!: Agent;
  selectedAgent!: Agent;
  private agentId: string = Guid.EMPTY;
  private agencyId: string = Guid.EMPTY;
  private _selectedAgent!: Agent;
  processReview?: processReview;
  isSelectedFromTable: boolean = false;

  constructor(private dialog: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private agencyAgentService: AgencyAgentService,
    private reviewService: ReviewService) {
  }

  ngOnInit(): void {
    this.subscribeToParams();
  }

  subscribeToParams() {
    this.route.params.subscribe(params => {
      this.documentId = params['id'];
      this.documentService.getUserForDocument(this.documentId)
        .subscribe(user => this.user = user,
          error => {
            if (error.status) {
              const message = createMessageContent(error.status);
              this.messageTitle = message?.title ?? '';
              this.errorMessages = [message?.messages[0] ?? ''];
            }
            else {
              this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
            }
          });
      this.documentService.getDocument(this.documentId)
        .subscribe(document => this.document = document,
          error => {
            if (error.status) {
              const message = createMessageContent(error.status);
              this.messageTitle = message?.title ?? '';
              this.errorMessages = [message?.messages[0] ?? ''];
            }
            else {
              this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
            }
          });
    });
  }

  openDocumentFile() {
    this.documentService.getDocumentFile(this.documentId)
      .subscribe((result: string) => {
        if (!result) {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
        return window.open(result, '_blank');
      },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.messageTitle = message?.title ?? '';
            this.errorMessages = [message?.messages[0] ?? ''];
          }
          else {
            this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
          }
        });
  }

  physicalAddressChange(value: any) {
    if (this.owner) {
      this.owner!.physicalAddress = value;
    }
  }

  mailingAddressChange(value: any) {
    if (this.owner) {
      this.owner!.mailingAddress = value;
    }
  }

  setOwnerType(ownerType: OwnerType) {
    this.harvestOwnerType = ownerType;
    
    if (this.ownerIsIndividual) {
      this.sbis = [{
        firstName: this.owner?.firstName ?? '',
        lastName: this.owner?.lastName ?? '',
        entityName: '',
        tinSuffix: this.owner?.tinSuffix ?? '',
        isEntity: this.owner?.isEntity ?? false
      }]
    }
    else {
      this.sbis = [{
        firstName: '',
        lastName: '',
        entityName: this.owner?.entityName ?? '',
        tinSuffix: this.owner?.tinSuffix ?? '',
        isEntity: this.owner?.isEntity ?? true
      }]
    }
  }

  setOwnerTypeAndData(data: { ownerType: OwnerType, owner: HarvestOwner }) {
    this.harvestOwnerType = data.ownerType;

    if (this.ownerIsIndividual) {
      this.sbis = [{
        firstName: data.owner?.firstName ?? '',
        lastName: data.owner?.lastName ?? '',
        entityName: '',
        tinSuffix: data.owner?.tinSuffix ?? '',
        isEntity: data.owner?.isEntity ?? false
      }]
    }
    else {
      this.sbis = [{
        firstName: '',
        lastName: '',
        entityName: data.owner?.entityName ?? '',
        tinSuffix: data.owner?.tinSuffix ?? '',
        isEntity: data.owner?.isEntity ?? true
      }]
    }
  }

  setHarvestOwner(value: HarvestOwner | undefined) {
    this.owner = value;
    if (this.owner) {
      this.isSelectedFromTable = true;
      if (this.owner.physicalAddress && this.owner.mailingAddress) {
        this.sameAsPhysical = this.addressesEqual(this.owner.physicalAddress, this.owner.mailingAddress);
      } else if (!(this.owner.physicalAddress && this.owner.mailingAddress)) {
        this.owner.physicalAddress = new Address();
        delete this.owner.physicalAddress['id'];
        delete this.owner.physicalAddress['fullAddress'];
        this.owner.mailingAddress = new Address();
        delete this.owner.mailingAddress['id'];
        delete this.owner.mailingAddress['fullAddress'];
        this.sameAsPhysical = true;
      } else {
        this.sameAsPhysical = this.owner.physicalAddress !== undefined;
      }
      if (this.owner.entityName.length === 0) {
        this.sbis = [{
          firstName: this.owner?.firstName ?? '',
          lastName: this.owner?.lastName ?? '',
          entityName: '',
          tinSuffix: this.owner?.tinSuffix ?? '',
          isEntity: this.owner?.isEntity ?? false
        }];
      }
      else {
        this.sbis = [{
          firstName: '',
          lastName: '',
          entityName: this.owner?.entityName ?? '',
          tinSuffix: this.owner?.tinSuffix ?? '',
          isEntity: this.owner?.isEntity ?? true
        }]
      }
    } else {
      this.sbis = [];
      this.sameAsPhysical = true;
      this.isSelectedFromTable = false;
    }
  }

  setFirstSBI(sbi: SBI) {
    this.sbis = (this.isSelectedFromTable === true) ? [] : this.sbis;
    this.sbis = [sbi, ...this.sbis];
  }

  addressesEqual(a: Address, b: Address): boolean {
    return a.address1 === b.address1 &&
      a.address2 === b.address2 &&
      a.city === b.city &&
      a.state === b.state &&
      a.postalCode === b.postalCode;
  }

  backToList() {
    if (this.hasChanges) {
      this.dialog.openUnsavedChangesDialog()
        .subscribe(proceed => {
          if (proceed) {
            this.router.navigate(['documents']);
          }
        });
    } else {
      this.router.navigate(['documents']);
    }
  }

  rejectDocument() {
    this.errorMessages = [];

    this.dialog.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Reject Document?', 'Are you sure you want to reject this document?', 'Cancel', 'Reject', true)
    }).subscribe(shouldReject => {
      if (shouldReject) {
        this.saving = true;
        this.documentService.rejectDocument(this.user!.id, this.documentId)
          .subscribe(_ => {
            this.saving = false;
            this.router.navigate(['documents', { rejected: btoa(JSON.stringify(this.user)) }]);
          },
            error => {
              if (error.status) {
                const message = createMessageContent(error.status);
                this.messageTitle = message?.title ?? '';
                this.errorMessages = [message?.messages[0] ?? ''];
              }
              else {
                this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
              }
            })
      }
    })
  }

  saveAgencyAgent() {
    this.errorMessages = [];
    this.saving = true;
    this.properties?.markAsTouched();
    this.agentAgency?.markAsTouched();
    if (!this.properties?.valid) {
      this.errorMessages = ['Enter required fields.'];
      this.saving = false;
      return;
    }

    if (!this.agentAgency?.valid) {
      this.errorMessages = ['Search for and select an Agent'];
      this.saving = false;
      return;
    }

    var agentRequest = this.agentAgency?.value!;

    if (agentRequest != null) {
      this.agencyId = Guid.EMPTY;
      if (agentRequest && agentRequest.agency && agentRequest?.agency.id != Guid.EMPTY) {
        this.agencyId = agentRequest.agency?.id!;
      }
      else if (this._selectedAgent && this._selectedAgent.agency) {
        this.agencyId = this._selectedAgent?.agency.id!;
      }

      this._selectedAgent = agentRequest;
      this._selectedAgent.address = agentRequest.address;
      this._selectedAgent.agency = {
        id: this.agencyId,
        name: agentRequest.agency.name,
        agencyCode: agentRequest.agency.agencyCode
      };

      if (agentRequest.id && agentRequest.id != Guid.EMPTY) {
        this.agencyAgentService.updateAgencyAgent(agentRequest)
          .subscribe(this.onAgentAgencyCallback.bind(this), error => {
            if (error.status) {
              const message = createMessageContent(error.status);
              this.messageTitle = message?.title ?? '';
              this.errorMessages = [message?.messages[0] ?? ''];
            }
            else {
              this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
            }
            this.saving = false;
          });
      }
      else {
        this.agencyAgentService.createAgencyAgent(agentRequest)
          .subscribe(this.onAgentAgencyCallback.bind(this), error => {
            if (error.status) {
              const message = createMessageContent(error.status);
              this.messageTitle = message?.title ?? '';
              this.errorMessages = [message?.messages[0] ?? ''];
            }
            else {
              this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
            }
            this.saving = false;
          });
      }
    }
  }

  establishHarvest() {
    this.saving = true;
    if (this.harvestOwner === undefined || this.harvestOwner === null) {
      this.errorMessages = ['Search for and select a Harvest Owner'];
      this.saving = false;
      return;
    }

    this.harvestOwner?.markAsTouched();
    this.addresses?.forEach(address => address.markAsTouched());
    this.sbisList?.markAsTouched();

    const step1Valid = (this.properties?.valid &&
      this.agentId !== Guid.EMPTY) ?? false;
    const step2Valid = (this.harvestOwner.valid &&
      this.addresses?.map(address => address.valid).reduce((curr, prev) => curr && prev, true) &&
      this.sbisList?.valid) ?? false;

    if (!step1Valid || !step2Valid) {
      this.errorMessages = ['Enter required fields'];
      this.saving = false;

      if (!step1Valid && !step2Valid) {
        this.accordion!.multi = true;
        this.accordion?.openAll();
      } else if (!step1Valid) {
        this.toStep(0)
      } else if (!step2Valid) {
        this.toStep(1);
      }
      return;
    }
    this.processReview = {
      plantedYear: this.properties?.value.year,
      userId: this.user?.id,
      policyNumber: this.properties?.value.policyNumber,
      aip: this.properties?.value.approvedInsuranceProvider,
      documentTypeId: this.properties?.value.documentType,
      agentId: this.agentId,
      state: this.properties?.value.state,
      cropTypes: this.properties?.value.cropTypes,
      harvestOwnerId: this.harvestOwner?.form.value.id,
      sbis: this.sbisList?.value,
      harvestOwner: this.harvestOwner?.value
    }
    this.processReview.harvestOwner!.mailingAddress = this.addresses?.last.form?.value;
    this.processReview.harvestOwner!.physicalAddress = this.addresses?.first?.form.value;

    this.reviewService.establishHarvest(this.processReview, this.documentId).subscribe(processReviewRsp => {
      if (processReviewRsp) {
        if (processReviewRsp.length === 0) {
          this.errorMessages = ['Harvest creation failed'];
          this.saving = false;
        }
        else {
          this.router.navigate(['documents', { accepted: btoa(JSON.stringify(this.user)) }]);
        }
      }
    }, error => {
      if (error.status) {
        const message = createMessageContent(error.status);
        this.messageTitle = message?.title ?? '';
        this.errorMessages = message?.messages ?? [];
      }
      else {
        this.errorMessages = ['Harvest creation failed'];
      }
      this.saving = false;
    })
  }

  toStep(index: number) {
    this.accordion!.multi = false;
    this.panels?.get(index)?.open();
  }

  markFirstTouched() {
    this.properties?.markAsTouched();
    this.agentAgency?.markAsTouched();
  }

  get ownerIsIndividual(): boolean {
    return this.harvestOwnerType === OwnerType.Individual;
  }

  get hasChanges(): boolean | undefined {
    const addressesTouched = this.addresses?.map(address => address.touched).reduce((curr, previous) => curr || previous, false);
    return this.properties?.touched || this.agentAgency?.touched || this.harvestOwner?.touched || addressesTouched || this.sbisList?.touched;
  }

  private onAgentAgencyCallback(result: Agent | null) {
    this.saving = false;
    this.agencyId = result?.agency.id!;
    this.agentId = result?.id!;

    if (this._selectedAgent != null) {
      //set returned ID and trigger AgencyAgentComponent selectedAgent input
      this._selectedAgent.id = this.agentId;
      if (this._selectedAgent.agency != null) {
        this._selectedAgent.agency.id = this.agencyId;
      }
      if (this._selectedAgent.address != null) {
        this._selectedAgent.address.id = result?.address.id;
      }
      this.selectedAgent = this._selectedAgent;
      this.savedAgent = { ...this._selectedAgent };
    }

    this.toStep(1);
  }
  HandleError(event: MessageContent) {
    if (event) {
      this.messageTitle = event?.title ?? '';
      this.errorMessages = event?.messages ?? [];
    }
  }
}
