import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class BaseService {

  constructor(private http: HttpClient) {}

  protected get<T>(url: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(url, {params: params})
      .pipe(
        catchError(this.onError)
      );
  }

  protected getMany<T>(url: string, params?: HttpParams): Observable<T[]> {
    return this.http.get<T[]>(url, {params: params})
      .pipe(
          map(result => {
            if (result == null) return [];

            return result;
          }),
          catchError(this.onError)
      )
  }

  protected post<T>(url: string, data = {}, options = {}): Observable<T> {
    return this.http.post<T>(url, data, options)
      .pipe(
        catchError(this.onError)
      )
  }

  protected put<T>(url: string, data = {}, options = {}): Observable<T> {
    return this.http.put<T>(url, data, options)
    .pipe(
      catchError(this.onError)
    )
  }

  protected putMany<T>(url: string, data = {}, options = {}): Observable<T[]> {
    return this.http.put<T[]>(url, data, options)
    .pipe(
      map(result => {
        if (result == null) return [];

        return result;
      }),
      catchError(this.onError)
    )
  }

  protected patch<T>(url: string, data = {}, options = {}): Observable<T> {
    return this.http.patch<T>(url, data, options)
      .pipe(
        catchError(this.onError)
      )
  }

  protected delete<T>(url: string, options = {}): Observable<T> {
    return this.http.delete<T>(url, options)
      .pipe(
        catchError(this.onError)
      )
  }

  private onError(err: any): Observable<any> {
    if (err.status === 200) {
      return of(err.error.text);
    }
    return throwError(err);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(error.error);
  }
}
