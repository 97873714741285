export interface RmaEntity {
  name: string;
  code: string;
  abbreviation: string;
  display: string;
}

export class AIP implements RmaEntity {
  name: string;
  code: string;
  abbreviation: string = '';
  get display(): string {
    return `${this.name} (${this.code})`;
  };

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

export class CropType implements RmaEntity {
  name: string;
  code: string;
  abbreviation: string = '';
  get display(): string {
    return `${this.name} (${this.code})`;
  };

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

export class InsurancePlan implements RmaEntity {
  name: string;
  code: string;
  abbreviation: string = '';
  get display(): string {
    return `${this.abbreviation} (${this.code})`;
  }

  constructor(code: string, abbreviation: string) {
    this.name = abbreviation;
    this.code = code;
    this.abbreviation = abbreviation;
  }
}

export class UnitStructure implements RmaEntity {
  name: string;
  code: string;
  abbreviation: string;
  
  get display(): string {
    return this.code;
  }

  constructor(name: string) {
    this.name = name;
    this.code = name;
    this.abbreviation = name;
  }

}