<form [formGroup]="form" class="flex full">
  <div class="flex vertical half">
    <mat-form-field>
      <input matInput formControlName="policyNumber" placeholder="Policy Number" required>
      <mat-error>
        <hh-validation-error message="Enter policy number."></hh-validation-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Document Type</mat-label>
      <mat-select formControlName="documentType" required>
        <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.id">{{ documentType.description }}</mat-option>
      </mat-select>
      <mat-error>
        <hh-validation-error message="Select document type."></hh-validation-error>
      </mat-error>
    </mat-form-field>
    <div class="flex">
      <div class="column">
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select formControlName="state" required>
            <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{ state.name }}</mat-option>
          </mat-select>
          <mat-error>
            <hh-validation-error message="Select state."></hh-validation-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
          <mat-label>Crop Year</mat-label>
          <mat-select formControlName="year" required>
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
          <mat-error>
            <hh-validation-error message="Select year."></hh-validation-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="flex vertical half">
    <mat-form-field>
      <mat-label>AIP/MGA</mat-label>
      <mat-select formControlName="approvedInsuranceProvider" required>
        <mat-option *ngFor="let aip of aips" [value]="aip.code">{{ aip.display }}</mat-option>
      </mat-select>
      <mat-error>
        <hh-validation-error message="Select AIP/MGA."></hh-validation-error>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="cropTypesFields" formArrayName="cropTypes" *ngFor="let cropType of cropTypesForm.controls; let i = index">
      <mat-label>Crop Type</mat-label>
      <mat-select [formControlName]="i" [required]="i===0" required>
        <mat-option [value]="type.code" *ngFor="let type of getCropList(i)">{{type.display}}</mat-option>
      </mat-select>
      <mat-error>
        <hh-validation-error message="Select crop type."></hh-validation-error>
      </mat-error>
    </mat-form-field>
    <a mat-button color="primary" (click)="removeCropType()" *ngIf="cropTypesForm.controls.length > 1"><mat-icon style="padding-top: 5px; font-size: 1rem;">remove_circle</mat-icon> Remove Additional Crop</a>
    <a mat-button color="primary" (click)="addCropType()"><mat-icon>add_circle</mat-icon> Include more Crop Types</a>
  </div>
</form>