<section>
  <mat-accordion>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <strong>Unit:</strong>&nbsp;{{unitNumber}}
      </mat-expansion-panel-header>
      <hh-messages [title]="messageTitle" [messages]="messages" [messageType]="messageType"></hh-messages>
      <section class="arrowed-sub">
        <h3>Yield History</h3>
        <hh-data-table
          [columnDefinitions]="columnDefinitions"
          [data]="yields"
        ></hh-data-table>
      </section>
      <mat-action-row>
        <button mat-flat-button color="primary" (click)="save()">Save</button>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</section>