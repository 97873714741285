import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { Unit, Yield } from 'src/app/models/boundary.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getUnitsForBoundary(boundaryId: string): Observable<Unit[]> {
    return this.getMany<Unit>(`harvest-history-api/documentreview/coverage/${boundaryId}/unit`);
  }

  getYieldHistoryForUnit(unitId: string): Observable<Yield[]> {
    return this.getMany<Yield>(`harvest-history-api/documentreview/unit/${unitId}/yieldhistory`);
  }

  updateYieldHistoryForUnit(unitId: string, yields: Yield[]): Observable<Yield[]> {
    return this.putMany<Yield>(`harvest-history-api/documentreview/unit/${unitId}/yieldhistory`, yields);
  }
}
