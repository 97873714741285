import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { Boundary } from 'src/app/models/boundary.model';
import { Harvest } from 'src/app/models/harvest.model';
import { BoundaryService } from 'src/app/services/boundary.service';
import { HarvestService } from 'src/app/services/harvest.service';

@Component({
  selector: 'hh-harvest-breadcrumbs',
  templateUrl: './harvest-breadcrumbs.component.html',
  styleUrls: ['./harvest-breadcrumbs.component.scss']
})
export class HarvestBreadcrumbsComponent implements OnInit {
  harvest?: Harvest;
  state: string = '';
  crop: string = ''
  boundary?: Boundary;
  county: string = '';
  userId: string = '';
  harvestId: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private harvestService: HarvestService,
    private boundaryService: BoundaryService) { }

  ngOnInit(): void {
    this.userId = this.route.parent?.snapshot.params['id'] ?? '';
    this.harvestId = this.route.snapshot.params['harvestId'];
    const boundaryId = this.route.snapshot.params['boundaryId'] ?? '';

    forkJoin({
      harvest: this.harvestService.getHarvestById(this.harvestId),
      boundary: boundaryId === '' ? of(null) : this.boundaryService.getBoundary(boundaryId)
    }).subscribe(result => {
      this.state = result.harvest.state;
      this.crop = result.harvest.cropType;
      this.county = result.boundary?.county ?? '';
    })
  }

  goToBoundary() {
    this.router.navigate([{outlets: {'harvests': ['harvest', this.harvestId, 'boundaries']}}], {relativeTo: this.route.parent});
  }
}
