<h2>Manage Users with Harvests</h2>
<hh-messages id="user_error_box" *ngIf="isUserListError" [title]="userListErrorTitle" messageType="alert" [messages]="userListError"></hh-messages>
<div class="search-wrapper">
  <form class="flex full" (ngSubmit)="applyFilter()">
    <mat-form-field>
      <input name="searchbox" matInput placeholder="Search users..." type="text" [(ngModel)]="search" />
    </mat-form-field>
      <button name="search" type="submit" mat-mini-fab color="primary" title="Search">
        <mat-icon>search</mat-icon>      </button>
    </form>
</div>
<mat-table [dataSource]="dataSource" matSort>
<ng-container matColumnDef="userName">
  <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
  <mat-cell *matCellDef="let element">{{element.lastName}}, {{element.firstName}}</mat-cell>
</ng-container>
<ng-container matColumnDef="email">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
  <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
</ng-container>
<ng-container matColumnDef="phone">
  <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
  <mat-cell *matCellDef="let element">{{element.phone | phone}}</mat-cell>
</ng-container>
<ng-container matColumnDef="actions">
  <mat-header-cell *matHeaderCellDef><span class="align-right">Actions</span></mat-header-cell>
  <mat-cell *matCellDef="let element">
    <span class="align-right">
      <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
    </span>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <div mat-menu-item class="mat-menu-header">
        <p><strong>Available Actions</strong></p>
      </div>
      <a mat-menu-item [routerLink]="['../users', element.userId]"><i class="fas fa-seedling"></i> View Harvests</a>
    </mat-menu>
  </mat-cell>
</ng-container>
<ng-container matColumnDef="noData">
  <mat-footer-cell *matFooterCellDef>
    <span *ngIf="loading">Loading...</span>
  </mat-footer-cell>
</ng-container>
<mat-header-row *matHeaderRowDef="['userName', 'email', 'phone', 'actions']"></mat-header-row>
<mat-row *matRowDef="let row; columns: ['userName', 'email', 'phone', 'actions']"></mat-row>
<mat-footer-row *matFooterRowDef="['noData']" [class.hide]="!loading"></mat-footer-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
