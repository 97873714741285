import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BaseService } from 'src/app/services/base.service';
import { Harvest } from 'src/app/models/harvest.model';
import { processReview } from '../models/process-review.model';
import { ReviewResponse } from '../models/harvest-review.model';
import { SBI } from '../models/sbi.model';
import { HarvestOwner } from '../models/harvest-owner.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  createReviewForHarvest(harvestId: string): Observable<Harvest> {
    return this.post<ReviewResponse>(`harvest-history-api/documentreview/harvest/${harvestId}/review`)
      .pipe(mergeMap(result => {
        return forkJoin({
          harvest: of(result.harvest) as Observable<Harvest>,
          sbis: this.getMany<SBI>(`harvest-history-api/documentreview/harvest/${result?.harvest?.id}/sbi`),
          harvestOwner: this.get<HarvestOwner>(`harvest-history-api/harvestOwner/${result.harvest.harvestOwnerId}`)
        });
      }), map(result => {
        result.harvest.sbis = result.sbis;
        result.harvest.harvestOwner = result.harvestOwner;

        return result.harvest;
      }));
  }
  
  establishHarvest(value: processReview, documentId: string): Observable<ReviewResponse[]> {
    return this.post<ReviewResponse[]>(`harvest-history-api/documentreview/document/${documentId}/review`, value);
  }
}
