<h3>Find existing Harvest Owner by:</h3>
<div class="flex full">
  <form class="flex vertical half" [formGroup]="form">
    <mat-radio-group aria-label="Select an option" (change)="changeOwnerType($event.value)">
      <div class="column left">
        <mat-radio-button [checked]="ownerTypeIs(ownerTypes.Individual)" [value]="ownerTypes.Individual" color="primary">Individual</mat-radio-button>
      </div>
      <div class="column right">
        <mat-radio-button [value]="ownerTypes.Entity" color="primary">Entity</mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="flex full" [class.hide]="!ownerTypeIs(ownerTypes.Individual)">
      <div class="column">
        <mat-form-field>
          <input matInput placeholder="First Name" formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
          <input matInput placeholder="Last Name" formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <mat-form-field [class.hide]="!ownerTypeIs(ownerTypes.Entity)">
      <input matInput placeholder="Entity Name" formControlName="entityName">
    </mat-form-field>
  </form>
  <div class="column">
    <button mat-stroked-button (click)="search()"><mat-icon>search</mat-icon> Search</button>
  </div>
</div>
<hh-messages [class.hide]="!searched" [title]="title" [messageType]="messageType" [messages]="messages">
  <br />
  <div *ngIf="searched && !noOwnersFound">
    <mat-table [dataSource]="harvestOwners">
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef class="mat-column-select"></mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-column-select">
                <mat-radio-button color="primary" (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"></mat-radio-button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Name">{{getName(row)}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="doingBusinessAs">
            <mat-header-cell *matHeaderCellDef>Doing Business As</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Doing Business As">{{row.doingBusinessAs}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="emailAddress">
            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Email">{{row.emailAddress}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef><span class="align-right">Phone Number</span></mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Phone Number"><span class="align-right">{{row.phoneNumber | phone}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="tin">
            <mat-header-cell *matHeaderCellDef><span class="align-right">Last 4 of TIN</span></mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="Last 4 of TIN"><span class="align-right">{{row.tinSuffix}}</span></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
    </mat-table>
    <br>
    <mat-radio-button color="primary" (click)="$event.stopPropagation()"
      (change)="$event ? selection.toggle(emptyOwner) : null"
      [checked]="selection.isSelected(emptyOwner)">None of the above</mat-radio-button>
  </div>
</hh-messages>