import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { forkJoin } from 'rxjs';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { Boundary, LegalDescription, PlantingInformation, Unit, UnitYield } from 'src/app/models/boundary.model';
import { AcceptedCharacters, Column, DataTableDefinition, MultipleColumnsRequiredDefinition } from 'src/app/data-table/models/data-table-definition.model';
import { IError } from 'src/app/models/error.model';
import { Guid } from 'src/app/models/guid';
import { ListChangeEvent, ListChangeType } from 'src/app/data-table/models/list-change-event.model';
import { RmaEntity } from 'src/app/models/rma.model';
import { SaveEvent, SaveType } from 'src/app/models/save-event.model';
import { BoundaryService } from 'src/app/services/boundary.service';
import { RmaService } from 'src/app/services/rma.service';

enum ListType {
  UNIT,
  LEGAL_DESCRIPTION,
  PLANTING_INFORMATION
}

@Component({
  selector: 'hh-add-edit-boundary',
  templateUrl: './add-edit-boundary.component.html',
  styleUrls: ['./add-edit-boundary.component.scss']
})
export class AddEditBoundaryComponent implements OnInit {
  @ViewChildren(DataTableComponent) dataTables?: QueryList<DataTableComponent>;
  county: string = '';
  units: Unit[] = [];
  messages: string[] = []
  @Input()
  set boundaryId(boundaryId: string | undefined) {
    if (boundaryId !== null && boundaryId !== undefined && boundaryId !== Guid.EMPTY) {
      this.getBoundary(boundaryId);
    }
    else {
      this.form.patchValue({id: Guid.EMPTY, county: '', planCode: '', level: '', isCatastrophic: false});
      this.setLevelEnabled(true);
      this.units = [];
    }
  }

  @Input() harvestId: string = '';

  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter<SaveEvent<Boundary>>();

  form!: FormGroup;
  plans: RmaEntity[] = [];
  @Input() counties: string[] = [];
  levels: number[] = [];

  unitColumnDefinitions: Column[] = [];
  private selectedUnitIndex: number | null = null;

  legalColumnDefinitions: Column[] = [
    new Column('id'),
    new Column('section', 'Section').asText(AcceptedCharacters.Numeric, 4, 3),
    new Column('township', 'Township').asText(AcceptedCharacters.AlphaNumeric, 4, 4),
    new Column('range', 'Range').asText(AcceptedCharacters.AlphaNumeric, 4, 4),
    new Column('fsn', 'FSN').asText(AcceptedCharacters.Numeric, 4, 3),
    new Column('tract', 'Tract').asText(AcceptedCharacters.Numeric, 5, 3),
    new Column('field', 'Field').asText(AcceptedCharacters.Numeric, 2, 1),
    new Column('subField', 'Sub Field').asText(AcceptedCharacters.Alpha, 1, 1)
  ];
  legalTableDefinition = new DataTableDefinition(this.legalColumnDefinitions,
    new MultipleColumnsRequiredDefinition(this.legalColumnDefinitions.slice(1, 4)).or([this.legalColumnDefinitions[4]]));
  private selectedLegalDescIndex: number | null = null;

  plantingInformationColumnDefinitions: Column[] = [
    new Column('id'),
    new Column('year', 'Year', {required: true, unique: true}).asText(AcceptedCharacters.Numeric, 4, 4),
    new Column('plantedDate', 'Plant Date').asDate(),
    new Column('acres', 'Planted Acres').asNumber(7, 2)
  ];

  listType = ListType;

  loading: boolean = true;

  constructor(builder: FormBuilder, private rmaService: RmaService, private boundaryService: BoundaryService, private dialog: DialogService) { 
    this.form = builder.group({
      id: '',
      county: ['', Validators.required],
      planCode: '',
      level: [{ value: '', disabled: false }],
      isCatastrophic: false
    });

    this.levels = [];
    for (let i = 65; i <= 100; i += 5) {
      this.levels.push(i / 100);
    }
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin({
      unitStructures: this.rmaService.getUnitStructures(),
      practiceTypes: this.boundaryService.getPracticeTypes(),
      plans: this.rmaService.getPlans()
    }).subscribe(result => {
      this.loading = false;
      this.plans = result.plans;
      this.unitColumnDefinitions = [
        new Column('id'),
        new Column('farmName', 'Farm Name').asText(),
        new Column('basicUnitNumber', 'Basic Unit #', {required: true}).asText(AcceptedCharacters.Numeric, 4, 4),
        new Column('optionalUnitNumber', 'Optional Unit #', {required: true}).asText(AcceptedCharacters.Numeric, 4, 4),
        new Column('unitStructure', 'Unit Structure').asSelect(result.unitStructures, 'abbreviation', 'display'),
        new Column('practiceTypeId', 'Practice Type', {required:true}).asSelect(result.practiceTypes, 'id', 'description'),
        new Column('highRiskLand', 'High Risk Land').asSelect().withDefaultValue(null)
      ]
    });
  }

  editList(event: ListChangeEvent, listType: ListType) {
    let list: any[] = this.getList(listType);

    if (event.changeType === ListChangeType.ADD) {
      const newItem:any = {...this.getNewItem(listType)};
      for (let prop in newItem) {
        if (event.data.hasOwnProperty(prop)) {
          newItem[prop] = event.data[prop];
        }
      }
      list.push(newItem);
    }
    else if (event.changeType === ListChangeType.EDIT) {
      for (let prop in list[event.index!]) {
        if (event.data.hasOwnProperty(prop)) {
          list[event.index!][prop] = event.data[prop]
        }
      }
    }
    else if (event.changeType === ListChangeType.SELECT) {
      this.changeIndex(listType, event.index);
    }
    else if (event.changeType === ListChangeType.DELETE) {
      list.splice(event.index!, 1);
      this.deleteChangeIndex(listType, event.index!);
    }
  }

  cancel() {
    const tablesTouched = this.dataTables?.reduce((prev, curr) => prev || curr.touched, false);
    if (this.form.touched || tablesTouched) {
      this.dialog.openUnsavedChangesDialog().subscribe(doCancel => {
        if (doCancel) {
          this.canceled.emit();
        }
      })
    } else {
      this.canceled.emit();
    }
  }

  save() {
    const tablesValid = this.dataTables?.reduce((prev, curr) => prev && curr.valid, true);
    const unitsWithoutLegals = this.units.filter(x => x.legalDescriptions.length === 0).length;
    if (!this.form.valid || !tablesValid || this.units.length === 0 || unitsWithoutLegals > 0) {
        this.form.markAllAsTouched();
        this.dataTables?.forEach(item => item.markAsTouched());
        if (this.units.length === 0 || unitsWithoutLegals > 0) {
          let type = 'unit';
          let parentType = 'boundary';
          if (unitsWithoutLegals > 0) {
            type = 'legal description';
            parentType = 'unit';
          }
          this.messages = [`Please add at least one ${type} for ${parentType}`];
        } else {
          this.messages = ['Enter required fields'];
        }
      return;
    }

    const boundary = {...this.form.value, units: [...this.units]} as Boundary;
    if(this.form.value.id === Guid.EMPTY) {
      let saveType = SaveType.ADD;
      this.boundaryService.addBoundary(this.harvestId, boundary).subscribe(
        result => this.afterSave(result, saveType),
        error => this.onError(error, saveType));
    }else {
      let saveType = SaveType.EDIT;
      this.boundaryService.updateBoundary(boundary).subscribe(
        result => this.afterSave(result, saveType),
        error => this.onError(error, saveType));
    }
  }

  toggleLevelEnable(changeEvt: MatCheckboxChange) {
    this.setLevelEnabled(!changeEvt.checked);
  }

  private setLevelEnabled(isEnabled: boolean) {
    const levelField = this.form.get('level');

    if (isEnabled) {
      levelField?.enable();
    }
    else {
      levelField?.reset()
      levelField?.disable();
    }
  }

  private afterSave(boundary: Boundary, eventType: SaveType) {
    this.saved.emit(new SaveEvent<Boundary>(eventType, boundary))
  }

  private onError(error: IError, eventType: SaveType) {
    let message = '';
    if (error.status === 404) {
      message = `${eventType === SaveType.ADD ? 'Harvest' : 'Coverage'} not found`;
    } else {
      message = error.error;
    }

    this.saved.emit(new SaveEvent<Boundary>(SaveType.ERROR, undefined, message));
    console.log(error);
  }

  private getBoundary(boundaryId: string) {
    this.boundaryService.getBoundary(boundaryId)
      .subscribe(boundary => {
        this.form.patchValue(boundary);
        this.setLevelEnabled(!boundary.isCatastrophic);
        this.county = boundary.county;
        this.units = boundary.units;
      })
  }

  private getList(listType: ListType) {
    if (listType === ListType.UNIT) {
      return this.units;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      return this.selectedUnit!.legalDescriptions;
    } else if (listType === ListType.PLANTING_INFORMATION) {
      return this.selectedLegalDesc!.plantingInformation;
    }

    return [];
  }

  private getNewItem(listType: ListType) {
    if (listType === ListType.UNIT) {
      return {
        farmName: '',
        basicUnitNumber: '',
        optionalUnitNumber: '',
        unitStructure: '',
        practiceTypeId: '',
        highRiskLand: '',
        legalDescriptions: new Array<LegalDescription>(),
        yields: new Array<UnitYield>()
      };;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      return {
        section: '',
        township: '',
        range: '',
        fsn: '',
        tract: '',
        field: '',
        subField: '',
        plantingInformation: new Array<PlantingInformation>()
      };
    } else if (listType === ListType.PLANTING_INFORMATION) {
      return {
        year: '',
        plantedDate: null,
        acres: null
      };
    }

    return null;
  }

  private changeIndex(listType: ListType, index: number | null) {
    if (listType === ListType.UNIT) {
      this.selectedUnitIndex = index;
      this.selectedLegalDescIndex = null;
    } else if (listType === ListType.LEGAL_DESCRIPTION) {
      this.selectedLegalDescIndex = index;
    }
  }

  private deleteChangeIndex(listType: ListType, index: number) {
    if (listType === ListType.UNIT && this.selectedUnitIndex === index) {
      this.changeIndex(listType, null);
    } else if (listType === ListType.LEGAL_DESCRIPTION && this.selectedLegalDescIndex === index) {
      this.changeIndex(listType, null);
    }
  }

  get selectedUnit(): Unit | null {
    return this.selectedUnitIndex !== null ? this.units[this.selectedUnitIndex] : null;
  }
  
  get selectedLegalDesc(): LegalDescription | null {
    return this.selectedLegalDescIndex !== null ? this.selectedUnit!.legalDescriptions[this.selectedLegalDescIndex] : null;
  }

  get touched(): boolean {
    const tablesTouched = this.dataTables?.reduce((prev, curr) => prev || curr.touched, false);
    return tablesTouched || this.form.touched;
  }

  get unitNumber(): string {
    let unitNumber = `${this.selectedUnit?.basicUnitNumber} - ${this.selectedUnit?.optionalUnitNumber}`;
    if (this.selectedUnit?.unitStructure) {
      unitNumber += ` - ${this.selectedUnit?.unitStructure}`;
    }
    return unitNumber;
  }

  get legalDescriptions(): LegalDescription[] {
    return this.selectedUnit?.legalDescriptions ?? [];
  }

  get plantingInformation(): PlantingInformation[] {
    return this.selectedLegalDesc?.plantingInformation ?? [];
  }
}
