import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Agent } from 'src/app/models/agency.model';
import { AgencyAgentService  } from 'src/app/services/agency-agent.service';
import { UserDocument } from 'src/app/models/grower-document.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { State, StatesList } from 'src/app/models/states.model';
import { Guid } from 'src/app/models/guid';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'hh-agent-search',
  templateUrl: './agent-search.component.html',
  styleUrls: ['./agent-search.component.scss']
})
export class AgentSearchComponent implements OnInit {
  @Output() onAgentSelected: EventEmitter<Agent> = new EventEmitter<Agent>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() 
  set document(userDocument: UserDocument) {
    if (userDocument != null) {
      this.documentService.getDocumentFile(userDocument.document?.id!).subscribe(url => {
        this.documentUrl = url;
      });
    }
  }

  @Input()
  set savedAgent(savedAgent: Agent) {
    if (savedAgent != null && savedAgent.id != Guid.EMPTY && this.agents != null) {
      let isFound = false;
      for(let i = 0; i < this.agents.length; ++i) {
        if (this.agents[i].id == savedAgent.id) {
          this.agents[i] = savedAgent;
          isFound = true;
          break;
        }
      }

      if (!isFound) {
        this.agents.push({...savedAgent});
      }

      this.areAgentsFound = true;
      this.selection.clear();
      this.setDataSource();
    }
  }

  emptyAgent: Agent = {
    id: Guid.EMPTY,
    agentCode: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: null!,
    agency: null!,
    fullName: ''
  };

  private documentUrl: string = '';

  form: FormGroup;
  areAgentsFound: boolean = false;
  hasSearched: boolean = false;
  isSearching: boolean = false;
  isSearchSuccessful: boolean = false;
  criteriaSearched: string = '';
  dataSource!: MatTableDataSource<Agent>;
  agents: Agent[] = [];
  filterQuery: string = '';
  selectedAgent!: Agent;

  displayColumns: string[] = ['select', 'agent_name', 'agency_name', 'city', 'state'];

  selection = new SelectionModel<Agent>(false);

  defaults = {
    pageIndex: 0,
    pageSize: 10
  };

  constructor(private builder: FormBuilder, private agencyAgentService: AgencyAgentService, private documentService: DocumentService) {
    this.form = this.builder.group({
      firstName: '',
      lastName: '',
      phoneNumber: ''
    });

    this.selection.changed.subscribe(_ => {
      if (this.selection.selected[0]) {
        this.selectedAgent = this.selection.selected[0];
        this.onAgentSelected.emit(this.selection.selected[0]);
      }
    });
  }

  ngOnInit(): void { }

  get title(): string {
    if (!this.areAgentsFound) {
      return 'No existing Agent found';
    }

    return 'Existing Agent Found';
  }

  get messages(): string[] {
    if (!this.hasSearched) {
      return [];
    }

    if (!this.isSearchSuccessful) {
      return ['An error occurred while attempting to search. Please try again.'];
    }
    
    if (!this.areAgentsFound) {
      return [`No Agent located for "${this.criteriaSearched}". Please provide more information found on the <a href="${this.documentUrl}" target="_blank"><strong>document</strong></a>.`];
    }

    return [(this.criteriaSearched !== '' ? `Existing Agent located for "${this.criteriaSearched}". ` : '') + `Please select the appropriate Agent or enter the details found on the <a href="${this.documentUrl}" target="_blank"><strong>document</strong></a>.`];
  }

  search(): void {
    this.agents = [];
    this.selection.clear();
    this.criteriaSearched = '';
    this.hasSearched = false;
    this.isSearching = true;
    this.areAgentsFound = false;
    this.isSearchSuccessful = false;

    let criteria = {
      firstName: this.form.get('firstName')?.value,
      lastName: this.form.get('lastName')?.value,
      phoneNumber: this.form.get('phoneNumber')?.value
    };

    this.agencyAgentService.searchAgents(criteria).subscribe(results => {
      this.areAgentsFound = results != null && results.length > 0;
      
      if (this.areAgentsFound) {
        this.agents = [...results];
      }

      if (criteria.firstName) {
        this.criteriaSearched += criteria.firstName;
      }

      if (criteria.lastName) {
        this.criteriaSearched += (criteria.firstName ? ' ' : '') + criteria.lastName;
      }

      if (criteria.phoneNumber) {
        this.criteriaSearched += (this.criteriaSearched ? ', ' : '') + criteria.phoneNumber;
      }

      if (this.areAgentsFound) {
        this.setDataSource();
      }
      else {
        this.onAgentSelected.emit(this.emptyAgent);
      }

      this.hasSearched = true;
      this.isSearchSuccessful = true;
      this.isSearching = false;
      this.clearSearchForm();
    },
    _ => {
      this.isSearchSuccessful = false;
      this.hasSearched = true;
      this.isSearching = false;
      this.clearSearchForm();
    });
  }

  searchChanged(): void {
    this.dataSource.filter = this.filterQuery.trim().toLowerCase();
  }

  getStateName(abbreviation: string): string {
    return State.getNameForAbbreviation(abbreviation);
  }

  private filterPredicate(agent: Agent, filter: string): boolean {
    if (!agent || !filter) { return false; }

    let dataStr: string = `${agent.firstName}|${agent.lastName}|${agent.phoneNumber}`;
    if (agent.hasOwnProperty('fullName')) { dataStr += `|${agent['fullName']}`; }
    if (agent.agency != null) { dataStr += `|${agent.agency.name}`; }
    if (agent.address != null) {
      let stateName = this.getStateName(agent.address.state);
      dataStr += `|${agent.address.city}|${agent.address.state}|{${stateName}`;
    }

    return dataStr.trim().toLowerCase().indexOf(filter.toLowerCase()) >= 0;
  }

  private setDataSource(): void {
    this.dataSource = new MatTableDataSource(this.agents);
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);

    window.setTimeout(() => { // Have to wrap in setTimeout here to allow Paginator element to render before setting properties
      this.dataSource.paginator = this.paginator;
      this.paginator.firstPage();
      this.searchChanged();
    }, 0);
  }

  private clearSearchForm(): void {
    this.form.get('firstName')?.setValue('');
    this.form.get('lastName')?.setValue('');
    this.form.get('phoneNumber')?.setValue('');
  }
}
