import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AIP, CropType, InsurancePlan, RmaEntity, UnitStructure } from 'src/app/models/rma.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RmaService {

  constructor(private http: HttpClient) { }

  getAIPs(): Observable<RmaEntity[]> {
    return this.get('approvedinsuranceproviders', x => new AIP(x.aipName, x.aipCode));
  }

  getCropTypes(): Observable<RmaEntity[]> {
    return this.get('commodities', x => new CropType(x.commodityName, x.commodityCode));
  }

  getPlans(): Observable<RmaEntity[]> {
    return this.get('insuranceplans', x => new InsurancePlan(x.planCode, x.planAbbreviation));
  }

  getUnitStructures(): Observable<RmaEntity[]> {
    return this.get('unitstructures', x => new UnitStructure(x.unitStructureCode));
  }

  private get(endpoint: string, mapFunc: (a: any) => RmaEntity): Observable<RmaEntity[]> {
    return this.http.get<any[]>(`rma/${endpoint}`)
      .pipe(map(list => {
        if (list == null) return [];
        return list.map(mapFunc).sort(this.sortEntities);
      }));
  }

  private sortEntities(a: RmaEntity, b: RmaEntity): number {
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  }
}
