import { Guid } from './guid';

export class Address {
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  postalCode: string = '';
  id?: string = Guid.EMPTY;
  fullAddress?: string = '';
}