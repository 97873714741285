import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ColumnType, Column, DataTableDefinition, MultipleColumnsRequiredDefinition } from 'src/app/data-table/models/data-table-definition.model';
import { Guid } from 'src/app/models/guid';
import { ListChangeEvent, ListChangeType } from 'src/app/data-table/models/list-change-event.model';
import { uniqueRowValidator } from 'src/app/data-table/validators/unique.validator';
import { MultipleColumnsRequiredValidator } from 'src/app/data-table/validators/multi-columns-required.validator';


@Component({
  selector: 'hh-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @ViewChild(MatTable) table?: MatTable<FormGroup>;
  @ViewChild(MatSort) sort!: MatSort;
  columnTypes = ColumnType;
  displayColumns: string[] = [];
  rowConfig: any = {};
  adding: boolean = false;
  uniqueColumnNames: string[] = []
  @Input()
  set columnDefinitions(columnDefinitions: Column[]) {
    this.setColumns(columnDefinitions);
  }
  @Input()
  set definition(definition: DataTableDefinition) {
    this.setColumns(definition.columns);
    this.requiredColumns = definition.requiredColumns;
    this.setupForm();
  }
  @Input() 
  set data (data: any[]) {
    this.dataList = data ?? [];
    this.fillForm();
  }
  
  @Input() itemType: string = '';
  @Input() managedItemType: string = '';

  @Input() minimumRequired: number = 0;
  
  @Output() listChanged = new EventEmitter<ListChangeEvent>();
  
  dataList: any[] = [];

  form!: FormArray;
  selectedIndex: number | null = null;

  columns: Column[] = [];

  requiredColumns?: MultipleColumnsRequiredDefinition;

  get tableData(): any[] {
    const values = (this.form.getRawValue() as any[]).filter(x => x.added).map(x => {
      let value = {...x};

      delete value['added'];
      if (value.id !== null && value.id !== undefined && value.id.length < 35) {
        value.id = Guid.EMPTY;
      }

      return value;
    });
    return values;
  }

  get touched(): boolean {
    return (this.form.controls as FormGroup[])
      .filter(x => x.value.added)
      .map(x => x.touched)
      .reduce((prev, curr) => prev || curr, false);
  }

  get valid(): boolean {
    const added = (this.form.controls as FormGroup[]).filter(x => x.value.added);
    return added.length >= this.minimumRequired &&
      added.map(x => x.valid)
        .reduce((prev, curr) => prev && curr, true);
  }

  constructor(private builder: FormBuilder) {
  }

  ngOnInit(): void {
  }

  addItem(group: FormGroup) {
    this.adding = true;
    group.get('added')?.setValue(true);
    this.form.push(this.generateNewGroup({'id': (this.form.length - 1) + '', 'added': false }));
    this.markAsTouched();

    this.table?.renderRows();
    this.listChanged.emit(new ListChangeEvent(ListChangeType.ADD, null, group.value));
    this.adding = false;
  }

  selectItem(group: FormGroup, index: number) {
    const value = group.value;
    const selectedItem = this.dataList.find(x => x.id == value.id) ?? value;
    this.selectedIndex = index;
    this.listChanged.emit(new ListChangeEvent(ListChangeType.SELECT, index, selectedItem));
  }

  deleteItem(index: number) {
    this.form.removeAt(index);
    this.table?.renderRows();
    this.markAsTouched();
    this.listChanged.emit(new ListChangeEvent(ListChangeType.DELETE, index));
    if (index === this.selectedIndex) {
      this.listChanged.emit(new ListChangeEvent(ListChangeType.SELECT));
      this.selectedIndex = null;
    }
  }

  markAsTouched() {
    for (let index = 0; index < this.form.length; index++) {
      if (this.form.at(index).value.added) {
        this.form.at(index).markAllAsTouched();
      }
    }
  }

  getErrorMessage(group: FormGroup, columnDef: Column) {
    let errors = group.get(columnDef.name)?.errors;
    if (errors) {
      let error = Object.keys(errors)[0];
      return columnDef.validationMessages[error];
    }
    
    return '';
  }

  changeSort(sort: Sort) {
    if (sort.active && sort.direction !== '') {
      let controls = this.form.controls.slice();
      this.form.controls = controls.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        if (a.value[sort.active] === '') return 1;
        if (b.value[sort.active] === '') return -1;
        return (a.value[sort.active] < b.value[sort.active] ? -1 : 1) * (isAsc ? 1: -1);
      });
    }
  }

  private fillForm() {
    this.selectedIndex = null;
    this.listChanged.emit(new ListChangeEvent(ListChangeType.SELECT));
    this.setupForm()
    for (const item of this.dataList) {
      const group = this.generateNewGroup({...item, added: true});
      this.form.insert(this.form.length - 1, group);
    }
    this.form.at(this.form.length - 1).get('id')?.setValue((this.form.length - 1) + '');
    this.table?.renderRows();
  }

  private onValueChanges(value: any) {
    if (!this.adding && value.added) {
      const index = (this.form.value as any[]).findIndex(x => x.id === value.id);
      const group = this.form.at(index);
      if (this.requiredColumns) {
        if (group.hasError('required')) {
          for (const name of group.getError('required')) {
            group.get(name)?.setErrors({'required': true});
          }
        } else {
          for (const name of this.requiredColumns!.allColumnNames) {
            if (group.get(name)?.hasError('required')) {
              group.get(name)?.setErrors(null);
            }
          }
        }
      }
      this.listChanged.emit(new ListChangeEvent(ListChangeType.EDIT, index, value));
    }
  }

  private setColumns(columns: Column[]) {
    this.columns = columns;
    this.displayColumns = columns.filter(x => !x.is(ColumnType.HIDDEN)).map(x => x.name) ?? [];
    this.uniqueColumnNames = this.columns.filter(x => x.unique).map(x => x.name);
    this.displayColumns.push('actions');
    
    this.rowConfig = Object.fromEntries(new Map(columns.map(x => [x.name, x.formControl])));
    this.rowConfig['added'] = '';
  }

  private setupForm() {
    this.form = this.builder.array([this.generateNewGroup()]);
    if (this.uniqueColumnNames.length > 0) {
      this.form.setValidators(this.uniqueColumnNames.map(name => uniqueRowValidator(name)));
    }
    this.form.valueChanges.subscribe(_ => {
      if (this.form.hasError('unique')) {
        const error = this.form.getError('unique');
        this.form.getError('unique').formGroups.forEach((group: FormGroup) => {
          group.get(error.controlName)?.setErrors({'unique': true});
          group.get(error.controlName)?.markAsTouched();
        });
      }
      else {
        (this.form.controls as FormGroup[]).forEach(group => {
          this.uniqueColumnNames.map(name => group.get(name)).filter(control => control?.hasError('unique')).forEach(control => {
            control?.setErrors(null);
            control?.updateValueAndValidity({onlySelf: true});
          });
        })
      }
    });
    this.form.at(0).get('added')?.setValue(false);
    this.form.at(0).valueChanges.subscribe(this.onValueChanges.bind(this));
  }

  generateNewGroup(value: any = null): FormGroup {
    let group = this.builder.group(this.rowConfig);

    if (this.requiredColumns) {
      group.setValidators(MultipleColumnsRequiredValidator(this.requiredColumns));
    }

    if (value) {
      group.patchValue(value);
    }

    group.valueChanges.subscribe(this.onValueChanges.bind(this));
    return group;
  }
}

