import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HarvestOwner } from '../models/harvest-owner.model';
import { BaseService } from './base.service';

export interface HarvestOwnerSearchCriteria {
  firstName: string;
  lastName: string;
  entityName: string;
  isEntity:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HarvestOwnerService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getHarvestOwners(searchCriteria: HarvestOwnerSearchCriteria): Observable<HarvestOwner[]> {
    return this.getMany<HarvestOwner>('harvest-history-api/harvestowner', new HttpParams({fromObject:{...searchCriteria, isEntity:searchCriteria.isEntity.toString() }}))
  }

  updateHarvestOwner(harvestOwner: HarvestOwner): Observable<HarvestOwner> {
    return this.patch<HarvestOwner>(`harvest-history-api/harvestowner/${harvestOwner.id}`, harvestOwner);
  }
}
