import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Review } from 'src/app/models/harvest-review.model';
import { DocumentService } from 'src/app/services/document.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { RmaService } from 'src/app/services/rma.service';
import { MessageType } from 'src/app/messages/messages.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { createMessageContent } from 'src/app/utility';
@Component({
  selector: 'hh-harvest-list',
  templateUrl: './harvest-list.component.html',
  styleUrls: ['./harvest-list.component.scss']
})
export class HarvestListComponent implements OnInit {
  dataSource = new MatTableDataSource<Review>();
  search: string = '';
  loading: boolean = false;
  showSuccess: boolean = false;
  successText = '';
  successMessageType: MessageType = 'success';
  errorText = '';
  errorTitle = '';
  messageType: MessageType = 'alert';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private harvestService: HarvestService,
    private documentService: DocumentService,
    private router: Router,
    private rmaService: RmaService,
    private dialog: DialogService
  ) { }


  ngOnInit(): void {
    this.subscribeToParams();
  }

  subscribeToParams(): void {
    this.getHarvests();
  }

  applyFilter = () => {
    this.dataSource.filter = this.search?.trim().toLocaleLowerCase() ?? '';
  }

  getHarvests() {
    this.loading = true;
    forkJoin({
      reviews: this.harvestService.getAllHarvestsForReview(),
      cropTypes: this.rmaService.getCropTypes()
    }).subscribe(result => {
      if (result.cropTypes.length === 0) {
        this.errorTitle = 'Error';
        this.errorText = 'The server is currently unavailable. Try refreshing this page, or navigate to the home page.';
        this.loading = false;
        return;
      }
      let reviews = result.reviews.map(r => {
        r.cropType = result.cropTypes.find(ct => ct.code == r.cropType)?.name ?? r.cropType
        return r;
      })
      this.dataSource.data = reviews;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: Review, property: string) => {
        switch (property) {
          case 'userName':
            return item.lastName + ', ' + item.firstName;
          default: return (item as any)[property];
        }
      }
      this.loading = false;
    },
      error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorText = message?.messages[0] ?? '';
        }
        else {
          this.errorTitle = 'Error';
          this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
        }
        this.loading = false;
      });
  }

  openDocumentFile(documentId: string) {
    this.documentService.getDocumentFile(documentId)
      .subscribe(result => {
        if (!result) {
          this.errorTitle = 'Error';
          this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
        }
        window.open(result, '_blank')
      },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorText = message?.messages[0] ?? '';
          }
          else {
            this.errorTitle = 'Error';
            this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
          }
        });
  }

  updateReview(reviewId: string, userId: string) {
    this.clearMessages();
    this.harvestService.updateReviewToLocked(reviewId)
      .subscribe(
        _ => {
          this.router.navigate(['users/', userId])
        },
        error => {
          if (error.status) {
            const message = createMessageContent(error.status);
            this.errorTitle = message?.title ?? '';
            this.errorText = message?.messages[0] ?? '';
          }
          else {
            this.errorTitle = 'Error';
            this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
          }
        }
      );
  }

  continueReview(userId: string) {
    this.clearMessages();
    this.router.navigate(['users/', userId])
  }

  completeReview(review: Review) {
    const userName = this.formatSystemUserName(review);
    this.clearMessages();
    this.dialog.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Complete Intake', 'Please confirm the "Harvest Intake" is complete for ' + userName + '?', 'Cancel', 'Complete')
    }).subscribe(shouldComplete => {
      if (shouldComplete) {
        this.harvestService.updateReviewToComplete(review.id)
          .subscribe(
            result => {
              this.dataSource.data = this.dataSource.data.filter(d => d != review);
              this.successText = 'Sucessfully completed harvest intake for ' + userName + '.'
              this.showSuccess = true;
            },
            error => {
              if (error.status) {
                const message = createMessageContent(error.status);
                this.errorTitle = message?.title ?? '';
                this.errorText = message?.messages[0] ?? '';
              }
              else {
                this.errorTitle = 'Error';
                this.errorText = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
              }
            }
          );
      }
    });
  }

  formatSystemUserName(review: Review): string {
    return review.lastName + ', ' + review.firstName;
  }

  clearMessages() {
    this.errorTitle = '';
    this.errorText = '';
    this.showSuccess = false;
    this.successText = '';
  }
}
