import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Harvest } from 'src/app/models/harvest.model';
import { forkJoin, Observable, of } from 'rxjs';
import { SBI } from '../models/sbi.model';
import { ServiceType } from '../models/type.model';
import { UserService } from './user-service.service';
import { RmaService } from './rma.service';
import { map, mergeMap } from 'rxjs/operators';
import { State, StatesList } from '../models/states.model';
import { Review } from '../models/harvest-review.model';
import { SystemUser } from '../models/system-user.model';

@Injectable({
  providedIn: 'root'
})
export class HarvestService extends BaseService {

  constructor(http: HttpClient, private userService: UserService, private rmaService: RmaService) {
    super(http);
  }

  getHarvestsForUser(userId: number): Observable<Harvest[]> {
    return this.getMany<Harvest>(`harvest-history-api/documentreview/user/${userId}/harvest`);
  }

  getHarvestById(harvestId: string): Observable<Harvest> {
    return forkJoin({
      harvest: this.get<Harvest>(`harvest-history-api/documentreview/harvest/${harvestId}`),
      cropTypes: this.rmaService.getCropTypes()
    }).pipe(map(result => {
      let harvest = result.harvest;
      harvest.cropType = result.cropTypes.find(x => x.code == harvest.cropType)?.name ?? harvest.cropType;
      harvest.state = State.getNameForAbbreviation(harvest.state);

      return harvest;
    }));
  }

  updateSBIs(harvestId: string, sbis: SBI[]): Observable<SBI[]> {
    return this.post<SBI[]>(`harvest-history-api/documentreview/harvest/${harvestId}/sbi`, sbis);
  }

  updateReviewToLocked(reviewId: string): Observable<Review | null> {
    return forkJoin({
      statuses: this.getReviewStatuses(),
      currentUser: this.userService.getProfile()
    })
    .pipe(
      mergeMap(result => {
        if (!result.statuses || result.statuses.length == 0 || !result.currentUser) {
          return of(null);
        }

        let locked = result.statuses.find(r => r.description.toLocaleLowerCase() == "locked");

        return this.put<Review>(`harvest-history-api/documentreview/${reviewId}`,
        {
          statusId: locked?.id ?? '',
          reviewerName: result.currentUser.displayName,
          reviewerId: result.currentUser.id
        });
      })
    );
  }

  updateReviewToComplete(reviewId: string): Observable<Review> {
    return this.post<Review>(`harvest-history-api/documentreview/${reviewId}/complete`, {});
  }

  getAllHarvestsForReview(): Observable<Review[]> {
    return this.getReviewStatuses()
    .pipe(
      mergeMap(statuses => {
        if (!statuses || statuses.length == 0) {
          return [];
        }

        const stats = statuses.filter(x => x.type.toLocaleLowerCase() === 'open' || x.type.toLocaleLowerCase() === 'locked');
        let query: HttpParams = new HttpParams();
        stats.forEach(status => query = query.append('statusid', status.id));
        return this.getMany<Review>('harvest-history-api/documentreview', query)
        .pipe(
          mergeMap(reviews  => {
            if(!reviews || reviews.length == 0) {
              return [];
            }

            return forkJoin({
              reviews: of(reviews),
              users: this.userService.getUserInfo(reviews.map((r:Review) => r.userId))
            });
          }),
          map(reviewUsers => {
            let reviews = reviewUsers.reviews.map(review => {
              const systemUser = reviewUsers.users.find(u => u.id === review.userId);
              let user: SystemUser = {
                id: systemUser?.id ?? 0,
                person: {
                    fullName: systemUser?.person?.fullName ?? '',
                    firstName: systemUser?.person?.firstName ?? '',
                    lastName: systemUser?.person?.lastName ?? '',
                    lastNameFirst : systemUser?.person.lastName ?? '',
                    cellPhone: systemUser?.person?.cellPhone ?? ''
                },
                email: systemUser?.email ?? 'not found'
              };

              review.firstName = user.person.firstName;
              review.lastName = user.person.lastName;
              review.email = user.email;
              review.userId = user.id;
              review.status = review.reviewerName != "" ? review.reviewerName : "Ready For Intake";
              review.state = StatesList.find(r => r.abbreviation == review.harvest.state)?.name ?? review.harvest.state;
              review.canStart = review.status != review.reviewerName;
              review.canComplete = review.status == review.reviewerName;
              review.canContinue = review.status == review.reviewerName;
              review.cropType = review.harvest.cropType;
              return review;
            });

            return reviews.sort((a, b) => a.firstName > b.firstName ? -1: 1);
          })
        );
      })
    );
  }

  private getReviewStatuses(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>(`harvest-history-api/documentreview/status`);
  }
}
