import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { MessageType } from 'src/app/messages/messages.component';
import { Unit } from 'src/app/models/boundary.model';
import { IHasChanges } from 'src/app/models/component.model';
import { UnitService } from 'src/app/services/unit.service';
import { createMessageContent } from 'src/app/utility';
import { EditYieldHistoryComponent } from './edit-yield-history/edit-yield-history.component';

@Component({
  selector: 'hh-yield-history',
  templateUrl: './yield-history.component.html',
  styleUrls: ['./yield-history.component.scss']
})
export class YieldHistoryComponent implements OnInit, IHasChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<Unit>;

  @ViewChild(EditYieldHistoryComponent) edit!: EditYieldHistoryComponent;

  boundaryId: string = '';
  unit?: Unit;
  dataSource: MatTableDataSource<Unit> = new MatTableDataSource<Unit>();
  search = ''
  displayColumns = ['basicUnitNumber', 'optionalUnitNumber', 'unitStructure', 'practiceType', 'highRiskLand', 'actions'];

  messageType: MessageType = 'success';
  messageTitle = '';
  messages: string[] = [];
  loading: boolean = false;
  savedUnitNumber: string = '';

  get noDataText(): string {
    let text = '';
    if (this.loading) {
      text = 'Loading...'
    } else {
      if (this.dataSource.data.length === 0) {
        text = 'No Units to display';
      } else if (this.dataSource.filteredData.length === 0) {
        text = 'No Units match the search';
      }
    }

    return text;
  }

  constructor(private route: ActivatedRoute, private unitService: UnitService, private dialog: DialogService) { }
  
  hasChanges(): boolean {
    return this.edit && this.hasChanges();
  }

  markAsClean(): void {
    this.unit = undefined
  }

  ngOnInit(): void {
    this.loading = true;
    this.boundaryId = this.route.snapshot.params['boundaryId'];
    this.unitService.getUnitsForBoundary(this.boundaryId)
      .subscribe(result => {
        this.dataSource.data = result ?? [];
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data, filter) => {
          filter = filter.toLowerCase();
          return data.basicUnitNumber.includes(filter) ||
                 data.optionalUnitNumber.includes(filter) ||
                 data.unitStructure.toLowerCase().includes(filter) ||
                 data.practiceType?.description.toLowerCase().includes(filter) ||
                 this.getHighRiskLandDisplay(data.highRiskLand).toLowerCase().includes(filter);
        };

        this.dataSource.sortingDataAccessor = (data, sortHeader) => {
          switch (sortHeader) {
            case 'practiceType':
              return data.practiceType?.description;
            case 'highRiskLand':
              return this.getHighRiskLandDisplay(data.highRiskLand);
            default:
              return (data as any)[sortHeader];
          }
        }
        this.loading = false;
      },
      error => {
        this.loading = false;

        this.messageType = 'alert';
        if (error.status) {
          const message = createMessageContent(error.status);
          this.messageTitle = message?.title ?? '';
          this.messages = message?.messages ?? [];
        }
        else {
          this.messageTitle = 'Error';
          this.messages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
      });
  }

  getHighRiskLandDisplay(highRiskLand: boolean | null | undefined): string {
    if (highRiskLand === null || highRiskLand === undefined) {
      return '';
    }
    return highRiskLand ? 'Yes' : 'No';
  }

  editYieldHistory(unit: Unit) {
    if (this.edit && this.edit.hasChanges()) { 
      this.dialog.openUnsavedChangesDialog().subscribe(change => {
        if (change) {
          this.unit = unit;
          this.messages = [];
        }
      });
    } else {
      this.unit = unit;
      this.messages = [];
    }
  }

  historySaved(unitNumber: string) {
    this.messageType = 'success';
    this.messageTitle = 'Save successful';
    this.messageType = 'success';
    this.messages = [`Unit ${unitNumber}'s yield history saved.`];
    this.unit = undefined;
  }

  applyFilter() {
    this.dataSource.filter = this.search;
  }

}
