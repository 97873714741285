import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Boundary } from '../models/boundary.model';
import { ServiceType } from '../models/type.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class BoundaryService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getBoundariesForHarvest(harvestId: string): Observable<Boundary[]> {
    return this.getMany<Boundary>(`harvest-history-api/documentreview/harvest/${harvestId}/coverage`);
  }

  deleteBoundary(boundaryId: string): Observable<string> {
    return this.delete<string>(`harvest-history-api/documentreview/coverage/${boundaryId}`);
  }

  getPracticeTypes(): Observable<ServiceType[]> {
    return this.getMany<ServiceType>('harvest-history-api/practicetype');
  }

  getBoundary(boundaryId: string): Observable<Boundary> {
    return this.get<Boundary>(`harvest-history-api/documentreview/coverage/${boundaryId}`);
  }

  updateBoundary(boundary: Boundary): Observable<Boundary> {
    return this.put(`harvest-history-api/documentreview/coverage/${boundary.id!}`, boundary);
  }

  addBoundary(harvestId: string, boundary: Boundary): Observable<Boundary> {
    return this.post(`harvest-history-api/documentreview/harvest/${harvestId}/coverage`, boundary);
  }
}
