<h3>{{addressType}} Address</h3>
<div *ngIf="showSameAsCheckbox">
  <mat-checkbox color="primary" [checked]="_sameAsOther" (change)="markSameAsOther($event)">Same as {{sameAsAddressType}}</mat-checkbox>
</div>
<form [formGroup]="form" *ngIf="!_sameAsOther" class="flex full">
  <div class="flex half vertical">
    <mat-form-field>
      <input matInput placeholder="Address 1" required formControlName="address1">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Address 2" formControlName="address2">
    </mat-form-field>
  </div>
  <div class="flex half">
    <div class="flex column vertical">
      <mat-form-field>
        <input matInput placeholder="City" required formControlName="city">
      </mat-form-field>
      <mat-form-field>
        <input matInput mask="00000" placeholder="ZIP" required formControlName="postalCode">
      </mat-form-field>
    </div>
    <div class="column">
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select required formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>