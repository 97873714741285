import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { Harvest } from 'src/app/models/harvest.model';
import { HarvestService } from 'src/app/services/harvest.service';
import { RmaService } from 'src/app/services/rma.service';
import { StatesList } from 'src/app/models/states.model';
import { getHarvestOwnerName } from 'src/app/models/utility';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ReviewService } from 'src/app/services/review.service';
import { HarvestDetailsComponent } from './harvest-details/harvest-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IHasChanges } from 'src/app/models/component.model';
import { MessageContent } from 'src/app/messages/messages.component';
import { AppEvent, EventBroadcastService } from 'src/app/services/event-broadcast.service';
import { createMessageContent } from 'src/app/utility';

@Component({
  selector: 'hh-user-harvests',
  templateUrl: './user-harvests.component.html',
  styleUrls: ['./user-harvests.component.scss']
})
export class UserHarvestsComponent implements OnInit, IHasChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(HarvestDetailsComponent) harvestDetails!: HarvestDetailsComponent;

  search: string ='';
  userId: number = 0;
  dataSource: MatTableDataSource<Harvest> = new MatTableDataSource<Harvest>();
  harvest?:Harvest;
  loading: boolean = false;

  constructor(
    private harvestService: HarvestService,
    private rmaService: RmaService,
    private reviewService: ReviewService,
    private dialogService: DialogService,
    private broadcastService: EventBroadcastService,
    private route: ActivatedRoute,
    private router: Router) { }
    
  hasChanges(): boolean {
    return this.harvestDetails ? this.harvestDetails.hasChanges() : false;
  }

  markAsClean(): void {
    this.harvest = undefined;
  }

  get noDataText(): string {
    if (this.loading) {
      return 'Loading...'
    } else {
      if (this.dataSource.data.length === 0) {
        return 'No Harvests to display';
      } else if (this.dataSource.filteredData.length === 0) {
        return 'No Harvests match your search';
      }
    }

    return '';
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userId = parseInt(params['id'], 10);
      this.getHarvests();
    })
  }

  getHarvests() {
    this.loading = true;
    forkJoin({
      harvests: this.harvestService.getHarvestsForUser(this.userId),
      cropTypes: this.rmaService.getCropTypes()
    }).subscribe(value => {
        let harvests = value.harvests.map(h => {
          const cropType = value.cropTypes.find(ct => ct.code === h.cropType);
          const state = StatesList.find(s => s.abbreviation === h.state);
          h.cropType = cropType?.display ?? '';
          h.state = state?.name ?? '';
          return h;
        });

        this.dataSource = new MatTableDataSource(harvests);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'harvestOwner': return this.getHarvestOwnerName(item.harvestOwner!);
            default: return (item as any)[property];
          }
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (item, filter) => {
          filter = filter.toLowerCase();
          return item.state.toLowerCase().includes(filter) ||
            item.cropType.toLowerCase().includes(filter) ||
            this.getHarvestOwnerName(item.harvestOwner!).toLowerCase().includes(filter);
        };

        this.loading = false;
      }, 
      error => {
        this.loading = false;
        this.raiseError(error);
      });
  }

  getHarvestOwnerName = getHarvestOwnerName;

  filterHarvests() {
    this.dataSource.filter = this.search;
  }

  harvestSaved(harvest: Harvest | undefined) {
    if (harvest === undefined) {
      this.harvest = undefined;
    } else {
      let data = [...this.dataSource.data];
      for(let item of data) {
        if (item.harvestOwner?.id === harvest.harvestOwner?.id) {
          item.harvestOwner = harvest.harvestOwner;
        }
      }
      this.dataSource.data = data;
    }
  }

  manageHarvestDetails(harvest: Harvest) {
    // if harvest has been set, the route guard won't check for unsaved changes because the outlet doesn't deactivate
    if (this.harvest && this.harvestDetails && this.harvestDetails.hasChanges()) {
      this.dialogService.openUnsavedChangesDialog()
        .subscribe(doLeave => {
          if (doLeave) {
            this.openHarvestOwnerDialog(harvest);
          }
        });

      return;
    }

    this.openHarvestOwnerDialog(harvest);
  }

  manageBoundaries(harvest: Harvest) {
    this.reviewService.createReviewForHarvest(harvest!.id!)
      .subscribe(result => {
        this.router.navigate([{outlets: {'harvests': ['harvest', result.id, 'boundaries']}}], {relativeTo: this.route});
      },
      this.raiseError.bind(this));
  }

  private openHarvestOwnerDialog(harvest: Harvest): void {
    this.dialogService.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Manage Harvest', 'All edits to the Harvest Owner are universal', 'Cancel', 'OK')
    }).subscribe(value => {
      if (value) {
        this.reviewService.createReviewForHarvest(harvest!.id!).subscribe(value => {
          this.harvest = value;
        },
        this.raiseError.bind(this));
      }
    });
  }

  private raiseError(error: any): void {
    let title: string = '';
    let message: string = '';

    if (error.status) {
      const messageContent = createMessageContent(error.status);
      title = messageContent?.title ?? '';
      message = messageContent?.messages[0] ?? '';
    }
    else {
      title = 'Error';
      message = 'An unknown error occurred. Try refreshing this page, or navigate to the home page.';
    }

    const msgContent: MessageContent = { title: title, messages: [message] };
    this.broadcastService.dispatch<MessageContent>(new AppEvent('message', msgContent));
  }
}
