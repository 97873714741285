import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ConfirmationDialogOptions } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { MessageType } from 'src/app/messages/messages.component';
import { Boundary } from 'src/app/models/boundary.model';
import { IHasChanges } from 'src/app/models/component.model';
import { Guid } from 'src/app/models/guid';
import { Harvest } from 'src/app/models/harvest.model';
import { SaveEvent, SaveType } from 'src/app/models/save-event.model';
import { BoundaryService } from 'src/app/services/boundary.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { State } from 'src/app/models/states.model';
import { AddEditBoundaryComponent } from './add-edit-boundary/add-edit-boundary.component';
import { createMessageContent } from 'src/app/utility';

@Component({
  selector: 'hh-manage-boundaries',
  templateUrl: './manage-boundaries.component.html',
  styleUrls: ['./manage-boundaries.component.scss']
})
export class ManageBoundariesComponent implements OnInit, IHasChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<Boundary>;
  @ViewChild(AddEditBoundaryComponent) editBoundary?: AddEditBoundaryComponent;
  harvestId: string = Guid.EMPTY;
  boundaries: Boundary[] = [];
  dataSource = new MatTableDataSource<Boundary>();
  harvest?: Harvest;

  search = '';

  loading = false;
  messageType: MessageType = 'success';
  messageTitle = '';
  messages: string[] = [];

  boundary?: Boundary;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private boundaryService: BoundaryService,
    private harvestService: HarvestService,
    private dialog: DialogService) { }

  hasChanges(): boolean {
    return this.editBoundary?.touched ?? false;
  }

  markAsClean(): void {
    this.boundary = undefined;
  }

  ngOnInit(): void {
    this.loading = true;
    this.harvestId = this.route.snapshot.params.harvestId;
    forkJoin({
      boundaries: this.boundaryService.getBoundariesForHarvest(this.harvestId),
      harvest: this.harvestService.getHarvestById(this.harvestId)
    }).subscribe(result => {
      this.dataSource.data = result.boundaries ?? [];
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.harvest = result.harvest;
      this.loading = false;
    },
    error => {
      this.messageType = 'alert';

      if (error.status) {
        const message = createMessageContent(error.status);
        this.messageTitle = message?.title ?? '';
        this.messages = message?.messages ?? [];
      }
      else {
        this.messageTitle = 'Error';
        this.messages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
      }
    });
  }

  applyFilter() {
    this.dataSource.filter = this.search;
  }

  cancelEdit() {
    this.boundary = undefined;
  }

  saved(event: SaveEvent<Boundary>) {
    this.messageTitle = '';
    this.messages = [];
    if (event.saveType === SaveType.ERROR) {
      this.messageType = 'alert';
      this.messages = [event.message];
    } else {
      this.messageTitle = 'Save successful.'
      this.messageType = 'success';
      this.messages = [`Boundary ${event.data!.county} saved successfully.`];
      if (event.saveType === SaveType.ADD) {
        const boundary = {...event.data!};
        boundary.units = [];
        this.dataSource.data = [...this.dataSource.data, boundary];
        this.table.renderRows();
      }
    }

    this.boundary = undefined;
  }

  get noDataText(): string {
    let text = '';
    if (this.loading) {
      text = 'Loading...'
    } else {
      if (this.dataSource.data.length === 0) {
        text = 'No boundaries for Harvest';
      } else if (this.dataSource.filteredData.length === 0) {
        text = 'No Boundaries match the search';
      }
    }

    return text;
  }

  goToYieldHistory(boundary:Boundary) {
    this.router.navigate([{outlets: {'harvests': ['harvest', this.harvestId, 'boundary', boundary.id, 'yields']}}], {relativeTo: this.route.parent});
  }

  manageBoundary(boundary: Boundary | null) {
    this.messages = [];
    this.messageTitle = '';
    this.boundary = boundary ?? {
      id: Guid.EMPTY,
      level: null,
      isCatastrophic: false,
      county: '',
      planCode: '',
      units: []
    };
  }

  deleteBoundary(boundaryId: string) {
    this.messageTitle = '';
    this.messages = [];
    this.dialog.openConfirmationDialog({
      width: '500px',
      data: new ConfirmationDialogOptions('Delete Boundary', 'Are you sure you want to delete this boundary and all of the data associated with it?', 'Cancel', 'Delete Boundary', true)
    }).subscribe(shouldDelete => {
      if (shouldDelete) {
        this.boundaryService.deleteBoundary(boundaryId)
          .subscribe(result => {
            let boundaries = [...this.dataSource.data];
            let index = boundaries.findIndex(x => x.id === result);
            if (index >= 0) {
              boundaries.splice(index, 1);
              this.dataSource.data = boundaries;
            }
            this.messageType = 'success';
            this.messageTitle = 'Success'
            this.messages = ['Boundary successfully deleted.'];
          }, error => {
            this.messageType = 'alert';
            
            if (error.status) {
              const message = createMessageContent(error.status);
              this.messageTitle = message?.title ?? '';
              this.messages = message?.messages ?? [];
            }
            else {
              this.messageTitle = 'Error on Page';
              this.messages = ['Oops. Something went wrong!'];
            }
          })
      }
    })
  }

  get counties(): string[] {
    const existingCounties = this.dataSource.data.map(x => x.county).filter(x => x !== this.boundary?.county);
    return State.getCountiesForName(this.harvest?.state ?? '').filter(x => !existingCounties.includes(x));
  }
}
