<header>
  <h2 class="no-margin">Setup Harvest History</h2>
  <div class="spacer"></div>
  <a mat-button color="primary" (click)="backToList()"><mat-icon>navigate_before</mat-icon> Back to Manage Documents</a>
</header>
<hh-messages id="error_box" *ngIf="errorMessages.length > 0" [title]="messageTitle" messageType="alert" [messages]="errorMessages"></hh-messages>
<header class="small-toolbar">
  <div class="spacer"></div>
  <a (click)="openDocumentFile()" color="primary"><mat-icon color="primary">picture_as_pdf</mat-icon> View Document</a>
</header>
<section class="sub-container">
  <mat-accordion>
    <mat-expansion-panel expanded="true" (closed)="markFirstTouched()">
      <mat-expansion-panel-header>
        <mat-panel-title><strong>Step 1:</strong>&nbsp;Confirm Document</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex half vertical">
        <br/>
          <mat-form-field>
            <input matInput placeholder="Email Address" disabled [value]="user?.email">
          </mat-form-field>
        <div class="flex full">
          <div class="column">
            <mat-form-field>
              <input matInput placeholder="First Name" disabled [value]="user?.person?.firstName">
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <input matInput placeholder="Last Name" disabled [value]="user?.person?.lastName">
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div class="arrowed-sub no-margin-bottom">
        <h3>Tell us some basics about the document.</h3>
        <hh-document-properties id ="documentprop_component" (onError)="HandleError($event)"></hh-document-properties>
      </div>
      <div class="arrowed-sub no-margin-bottom">
        <hh-agent-search  id ="search_component" [document]="document" (onAgentSelected)="selectedAgent = $event" [savedAgent]="savedAgent"></hh-agent-search>
        <hh-agent-agency *ngIf="selectedAgent" [selectedAgent]="selectedAgent"></hh-agent-agency>
      </div>
      <mat-action-row>
        <button mat-stroked-button (click)="rejectDocument()">Reject</button>
        <button mat-flat-button color="primary" (click)="saveAgencyAgent()">
          Accept Document <mat-icon>navigate_next</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel id="step_2" (opened)="markFirstTouched()">
      <mat-expansion-panel-header>
        <mat-panel-title><strong>Step 2:</strong>&nbsp;Establish Harvest Owner</mat-panel-title>
      </mat-expansion-panel-header>
      <br/>
      <hh-harvest-owner-search id = "harvest_search"[documentId]="documentId" (selectedHarvestOwner)="setHarvestOwner($event)" (harvestOwnerType)="setOwnerType($event)"></hh-harvest-owner-search>
      <section *ngIf="owner">
        <div class="arrowed-sub no-margin-bottom">
          <h3>Who is the policy holder?</h3>
          <hh-harvest-owner-details [harvestOwner]="owner" [harvestOwnerType]="harvestOwnerType" (harvestOwnerTypeChangeWithFormData)="setOwnerTypeAndData($event)" (harvestOwnerTypeChange)="setOwnerType($event)" (ownerAsSBI)="setFirstSBI($event)"></hh-harvest-owner-details>
        </div>
        <div class="arrowed-sub no-margin-bottom">
          <hh-address-details
            addressType="Physical"
            [address]="owner?.physicalAddress"
            (addressChange)="physicalAddressChange($event)"
          ></hh-address-details>
        </div>
        <div class="arrowed-sub no-margin-bottom">
          <hh-address-details
            addressType="Mailing"
            sameAsAddressType="Physical"
            [showSameAsCheckbox]="true"
            [address]="owner?.mailingAddress"
            [otherAddress]="owner?.physicalAddress"
            [sameAsOther]="sameAsPhysical"
            (addressChange)="mailingAddressChange($event)"
          ></hh-address-details>
        </div>
        <div class="arrowed-sub no-margin-bottom">
          <h3>List all SBI information found on the document.</h3>
          <hh-sbis-list [firstRowReadonly]="true" [sbis]="sbis"></hh-sbis-list>
        </div>
      </section>
      <mat-action-row>
        <button mat-stroked-button (click)="toStep(0)"><i class="fas fa-angle-left"></i> Confirm Document</button>
        <button mat-flat-button color="primary" (click)="establishHarvest()">Establish Harvest</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</section>