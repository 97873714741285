import { Component, Input, OnInit, ViewChild , EventEmitter, Output} from '@angular/core';
import { DataTableComponent } from 'src/app/data-table/data-table.component';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { MessageType } from 'src/app/messages/messages.component';
import { Unit, Yield } from 'src/app/models/boundary.model';
import { AcceptedCharacters, Column } from 'src/app/data-table/models/data-table-definition.model';
import { IError } from 'src/app/models/error.model';
import { UnitService } from 'src/app/services/unit.service';

@Component({
  selector: 'hh-edit-yield-history',
  templateUrl: './edit-yield-history.component.html',
  styleUrls: ['./edit-yield-history.component.scss']
})
export class EditYieldHistoryComponent implements OnInit {
  @ViewChild(DataTableComponent) table!: DataTableComponent;
  @Output() cancelled = new EventEmitter();
  @Output() saved = new EventEmitter<string>();
  yields: Yield[] = [];
  
  @Input()
  set unit(unit: Unit) {
    if (unit) {
      this.unitNumber = `${unit.basicUnitNumber} - ${unit.optionalUnitNumber}`;
      if (unit.unitStructure.length > 0) {
        this.unitNumber += ` - ${unit.unitStructure}`;
      }
      this.unitId = unit.id!;
      this.getData();
    }
  }
  
  columnDefinitions: Column[] = [
    new Column('id'),
    new Column('year', 'Year', {required: true, sortable: true, unique: true}).asText(AcceptedCharacters.Numeric, 4, 4),
    new Column('production', 'Total Prod').asNumber(7, 2).withDefaultValue(null),
    new Column('acres', 'Acres', {required: true}).asNumber(7, 2),
    new Column('actualYield', 'Actual', {readonly: true}).asNumber(5, 2).withCalculation((value: any) => value.production === null || value.production === '' ? null : (value.production ?? 0) / value.acres),
    new Column('aggregateActualYield', 'Aggregate Average').asNumber(7, 2).asNumber(7, 2).withDefaultValue(null),
    new Column('aggregateApprovedYield', 'Aggregate Approved').asNumber(7, 2).asNumber(7, 2).withDefaultValue(null),
    new Column('aggregateAdjustedYield', 'Aggregate Adjusted').asNumber(7, 2).asNumber(7, 2).withDefaultValue(null)
  ];

  unitNumber: string = '';
  unitId: string = '';

  messageType: MessageType = 'success';
  messages: string[] = [];
  messageTitle: string = '';
  
  constructor(private unitService: UnitService, private dialog: DialogService) { }

  ngOnInit(): void {
  }

  hasChanges(): boolean {
    return this.table.touched;
  }

  private getData() {
    this.unitService.getYieldHistoryForUnit(this.unitId)
      .subscribe(yields => {
        this.yields = yields.map(x => {
          (x as any).actualYield = (x.production ?? 0) / x.acres;
          return x;
        }).sort((y1, y2) => {
          return parseInt(y1.year) < parseInt(y2.year) ? -1 : 1;
        });
      });
  }

  save() {
    this.messages = [];
    if (!this.table?.valid) {
      // show errors
      this.messageType = 'alert';
      this.messages = ['Enter required fields'];
      return;
    }

    this.unitService.updateYieldHistoryForUnit(this.unitId, this.table?.tableData)
      .subscribe(_ => {
        this.saved.emit(this.unitNumber);
      }, (err: IError) => {
        this.messageType = 'alert';
        this.messages = [err.message]
      })
  }

  cancel() {
    if (this.table?.touched) {
      this.dialog.openUnsavedChangesDialog()
        .subscribe(doTheThing => {
          if (doTheThing) this.cancelled.emit();
        });
    }
    else {
      this.cancelled.emit();
    }
  }

}
