<form class="flex vertical full" [formGroup]="form">
  <div class="flex full">
    <div class="flex vertical half" formGroupName="agent">
      <div class="flex full">
        <div class="column">
          <mat-form-field>
            <input matInput placeholder="Agent First Name" required formControlName="firstName">
            <mat-error>
                <hh-validation-error message="Enter agent first name."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="column">
          <mat-form-field>
            <input matInput placeholder="Agent Last Name" required formControlName="lastName">
            <mat-error>
                <hh-validation-error message="Enter agent last name."></hh-validation-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field>
        <input matInput mask="(000) 000-0000" placeholder="Agent Phone Number" required formControlName="phoneNumber">
        <mat-error>
          <hh-validation-error [message]="phoneValidationError"></hh-validation-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Agent Email" formControlName="email">
        <mat-error>
            <hh-validation-error message="Enter valid email."></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex vertical half">
      <mat-form-field formGroupName="agent">
        <input matInput placeholder="Agent Code" formControlName="agentCode">
      </mat-form-field>
      <div class="flex vertical full" formGroupName="address">
        <mat-form-field>
          <input matInput placeholder="Agent Address 1" required formControlName="address1">
          <mat-error>
              <hh-validation-error message="Enter address 1."></hh-validation-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Agent Address 2" formControlName="address2">
        </mat-form-field>
        <div class="flex full">
          <div class="flex vertical column">
            <mat-form-field>
              <input matInput placeholder="Agent City" required formControlName="city">
              <mat-error>
                  <hh-validation-error message="Enter city."></hh-validation-error>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput mask="00000" placeholder="Zip Code" required formControlName="postalCode">
              <mat-error>
                <hh-validation-error [message]="postalCodeValidationError"></hh-validation-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
            <mat-form-field>
              <mat-label>State</mat-label>
              <mat-select required formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</mat-option>
              </mat-select>
              <mat-error>
                <hh-validation-error message="Select state."></hh-validation-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex full">
    <div class="flex half">
      <mat-form-field>
        <input matInput placeholder="Agency Name" required formControlName="agencyName">
        <mat-error>
          <hh-validation-error message="Enter agency name."></hh-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex half">
      <mat-form-field>
          <input matInput placeholder="Agency Code" formControlName="agencyCode">
      </mat-form-field>
    </div>
  </div>
</form>