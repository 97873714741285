import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageType } from 'src/app/messages/messages.component';
import { commasSeparatedWithConjunction, createMessageContent, fieldHasError } from 'src/app/utility';
import { DocumentService } from 'src/app/services/document.service';
import { UserService } from 'src/app/services/user-service.service';
import * as config from 'src/assets/configuration.json';

@Component({
  selector: 'hh-add-document',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddDocumentComponent implements OnInit {
  private readonly emailMessaging = {
    required: {
      title: 'No email address',
      message: 'Please provide an email address to search for a system user.'
    },
    email: {
      title: 'Invalid email address',
      message: 'The email address is not valid. Enter a valid email address.'
    }
  }

  fileForm = this.builder.group({
    fileInput: ''
  });

  searchForm: FormGroup = this.builder.group({
    email: ['', [Validators.required, Validators.email]]
  });

  userId: number = -1;
  firstName: string = '';
  lastName: string = '';

  errored!: boolean;
  succeeded!: boolean;
  noSearchResults!: boolean;
  userNotFound: boolean = true;

  searched!: boolean;
  searching!: boolean;

  saving!: boolean;
  saved!: boolean;

  showForm: boolean = false;

  files: FileList | null = null;
  supportedFileTypes: string[] = [];

  attempt = 0;

  messageTitle: string = '';
  messages: string[] = [];

  constructor(
    private builder: FormBuilder,
    private documentService: DocumentService,
    private userService: UserService) {
    }

  ngOnInit(): void {
    this.documentService.getDocumentFileTypes().subscribe(
      types => {
        if (types.length > 0) {
          for (let i = 0; i < types.length; ++i) {
            this.supportedFileTypes.push(types[i].type.toUpperCase());
          }
        }
        else {
          this.errored = true;
          this.messageTitle = 'Error';
          this.messages = ['Failed to retrieve list of allowed file types. Try refreshing this page, or navigate to the home page'];
        }
      },
      error => {
        this.errored = true;
        
        if (error.status) {
          const message = createMessageContent(error.status);
          this.messageTitle = message?.title ?? '';
          this.messages = message?.messages ?? [];
        }
        else {
          this.messageTitle = 'Error';
          this.messages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
      }
    );
  }

  search() {
    this.searched = false;
    this.succeeded = false;
    this.errored = false;
    this.noSearchResults = false;
    this.messageTitle = '';
    this.messages = [''];
    this.showForm = false;
    if (this.searching) {
      return;
    }

    if (!this.searchForm.valid) {
      if (this.searchForm.get('email')?.hasError('required')) {
        this.messageTitle = this.emailMessaging.required.title;
        this.messages = [this.emailMessaging.required.message];
      }
      else if (this.searchForm.get('email')?.hasError('email')) {
        this.messageTitle = this.emailMessaging.email.title;
        this.messages = [this.emailMessaging.email.message];
      }

      this.errored = true;

      return;
    }

    this.searching = true;
    const trimmedEmail = this.searchForm.get('email')?.value.trim();

    this.userService.getUserByEmail(trimmedEmail)?.subscribe(user => {
      this.searching = false;
      this.searched = true;

      if (user != null && user.person != null) {
        // System user found
        this.succeeded = true;
        this.messageTitle = 'Existing System User Found';
        this.messages = ['The Email Address provided has an existing System User associated with it.'];
        this.showForm = true;
        this.userId = user.id;
        this.firstName = user.person.firstName;
        this.lastName = user.person.lastName;
        this.userNotFound = false;
      }
      else {
        this.handleNoUserFound();
      }
    },
    error => {
      this.searching = false;
      this.searched = true;
        
      if (error.status) {
        this.errored = true;
        if (error.status == 404) {
          this.messageTitle = 'Email not found';
          this.messages = [`The email does not have a System User associated to it. Please add a new <a href="${config.farmManagerUri}" target="_blank">System User</a>.`];
        }
        else {
          const message = createMessageContent(error.status);
          this.messageTitle = message?.title ?? '';
          this.messages = message?.messages ?? [];
        }
      }
      else {
        this.handleNoUserFound();
      }
    });
  }

  private handleNoUserFound(): void {
    // No system user found.
    this.succeeded = true;
    this.noSearchResults = true;
    this.messageTitle = 'No Results Found';
    this.messages = [`User does not have any associated harvests.`];
    this.userNotFound = true;
  }

  fileChanged(event: any) {
    this.files = event.target.files;
  }

  reset() {
    this.fileForm.controls.fileInput.setValue('');
    this.files = null;
  }

  upload() {
    this.saved = false;
    this.saving = true;
    this.succeeded = false;
    this.errored = false;
    this.searched = false;
    this.noSearchResults = false;
    this.messageTitle = '';
    this.messages = [''];

    if (!this.searchForm.valid) {
      this.errored = true;
      this.saving = false;

      if (this.searchForm.get('email')?.hasError('required')) {
        this.messageTitle = this.emailMessaging.required.title;
        this.messages = [this.emailMessaging.required.message];
      }
      else if (this.searchForm.get('email')?.hasError('email')) {
        this.messageTitle = this.emailMessaging.email.title;
        this.messages = [this.emailMessaging.email.message];
      }
      
      return;
    }

    if (!this.files) {
      this.errored = true;
      this.saving = false;
      this.messages = ['Please select a file to upload.'];
      return;

    }

    if (!this.isValidFileFormat) {
      this.errored = true;
      this.saving = false;
      this.messages = [`File is of the wrong type. Supported file type${this.supportedFileTypes.length === 1 ? ' is' : 's are'} ${this.fileFormats}.`];
      return;
    }

    this.documentService.uploadDocument(this.userId, this.files[0])?.subscribe(_ => {
      this.saved = true;
      this.messageTitle = 'Success';
      this.messages = ['File has saved.']
      this.reset();
      this.saving = false;
    },
    error => {
      if (error.status) {
        const message = createMessageContent(error.status);
        this.messageTitle = message?.title ?? '';
        this.messages = message?.messages ?? [];
      }
      else {
        this.messageTitle = 'Error on Page';
        this.messages = ['Unable to upload document'];
      }

      this.saving = false;
      this.reset();
      this.errored = true;
    });
  }

  private get isValidFileFormat(): boolean {
    const fullType = this.files?.item(0)?.type ?? '';
    const fileType = fullType.substr(fullType.indexOf('/') + 1).toUpperCase() ?? '';
    return this.supportedFileTypes.includes(fullType) ||this.supportedFileTypes.includes(fileType);
  }

  get fileFormats(): string {
    if (this.supportedFileTypes) {
      return commasSeparatedWithConjunction(this.supportedFileTypes.map(x => x.substring(x.indexOf('/') + 1).toUpperCase()));
    }
    return '';
  }

  get messageType(): MessageType { return (this.errored || this.noSearchResults) ? 'alert' : 'success'; }

  get emailErrorMessage(): string {
    if (fieldHasError(this.searchForm, 'email', 'required')) {
      return 'Enter email address.';
    }

    if (fieldHasError(this.searchForm, 'email', 'email')) {
      return 'The email address is not valid. Enter a valid email address.';
    }

    return '';
  }
}
