import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Agent } from 'src/app/models/agency.model';
import { StatesList } from 'src/app/models/states.model';
import { fieldHasError } from 'src/app/utility';
import { Address } from "src/app/models/address.model";
import { Guid } from 'src/app/models/guid';

@Component({
  selector: 'hh-agent-agency',
  templateUrl: './agent-agency.component.html',
  styleUrls: ['./agent-agency.component.scss']
})
export class AgentAgencyComponent {
  @Input()
  set selectedAgent(agent: Agent | undefined) {
    if (agent != null) {
      this._selectedAgent = {...agent};

      // Have to wrap in setTimeout call here to get the page to redraw properly after selecting a radio button and emitting the value.
      // Thanks, Angular!
      window.setTimeout(() => {
        this.form.patchValue({
          agencyName: agent.agency ? agent.agency.name : '',
          agencyCode: agent.agency ? agent.agency.agencyCode : '',
          address: agent.address ? {...agent.address} : new Address(),
          agent: {...agent}
        });
      }, 0);
    }
  }

  states = StatesList;
  form: FormGroup;

  private _selectedAgent!: Agent;

  constructor(builder: FormBuilder) {
    this.form = builder.group({
      agencyName: ['', Validators.required],
      agencyCode: '',
      address: builder.group({
        address1: ['', Validators.required],
        address2: '',
        city: ['', Validators.required],
        state: ['', Validators.required],
        postalCode: ['', Validators.required]
      }),
      agent: builder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        agentCode: '',
        phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        email: ['', Validators.email]
      })
    });
  }

  markAsTouched() {
    this.form.markAllAsTouched();
  }

  markAsUntouched() {
    this.form.markAsUntouched();
  }

  get touched(): boolean {
    return this.form.touched;
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get value(): Agent {
    let value = {...this.form.value};
    let agent = {...this._selectedAgent};

    agent.firstName = value.agent.firstName;
    agent.lastName = value.agent.lastName;
    agent.agentCode = value.agent.agentCode;
    agent.phoneNumber = value.agent.phoneNumber;
    agent.email = value.agent.email;

    if (agent.address != null) {
      agent.address.address1 = value.address.address1;
      agent.address.address2 = value.address.address2;
      agent.address.city = value.address.city;
      agent.address.state = value.address.state;
      agent.address.postalCode = value.address.postalCode;
    }
    else {
      agent.address = value.address;
    }

    if (agent.agency != null) {
      agent.agency = {
        id: agent.agency.id,
        agencyCode: value.agencyCode,
        name: value.agencyName
      };
    }
    else {
      agent.agency = {
        id: Guid.EMPTY,
        agencyCode: value.agencyCode,
        name: value.agencyName
      };
    }

    return agent;
  }

  get phoneValidationError(): string {
    let form: FormGroup = this.form.get('agent') as FormGroup;
    if (fieldHasError(form, 'phoneNumber', 'required')) {
      return 'Enter phone number.';
    }
    if (fieldHasError(form, 'phoneNumber', 'minlength') || fieldHasError(form, 'phoneNumber', 'maxlength')) {
      return 'Phone number must be 10 digits.';
    }
    return '';
  }

  get postalCodeValidationError(): string {
    let form: FormGroup = this.form.get('address') as FormGroup;
    if (fieldHasError(form, 'postalCode', 'required')) {
      return 'Enter zip code.';
    }
    if (fieldHasError(form, 'postalCode', 'mask')) {
      return 'Zip code must be 5 digits.';
    }
    return '';
  }
}
