import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { SBIsListComponent } from 'src/app/harvests/sbis-list/sbis-list.component';
import { HarvestOwnerDetailsComponent } from 'src/app/harvests/harvest-owner-details/harvest-owner-details.component';
import { MessageType } from 'src/app/messages/messages.component';
import { Policy } from 'src/app/models/agency.model';
import { IHasChanges } from 'src/app/models/component.model';
import { SBI } from 'src/app/models/sbi.model';
import { Guid } from 'src/app/models/guid';
import { HarvestOwner, OwnerType } from 'src/app/models/harvest-owner.model';
import { Harvest } from 'src/app/models/harvest.model';
import { AgencyAgentService } from 'src/app/services/agency-agent.service';
import { HarvestOwnerService } from 'src/app/services/harvest-owner.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { PolicyInformationComponent } from '../policy-information/policy-information.component';

@Component({
  selector: 'hh-harvest-details',
  templateUrl: './harvest-details.component.html',
  styleUrls: ['./harvest-details.component.scss']
})
export class HarvestDetailsComponent implements OnInit, IHasChanges {
  @ViewChild(HarvestOwnerDetailsComponent) harvestOwnerDetails!: HarvestOwnerDetailsComponent;
  @ViewChild(SBIsListComponent) sbisList!: SBIsListComponent;
  @ViewChildren(PolicyInformationComponent) policiesList!: QueryList<PolicyInformationComponent>;

  private _harvest: Harvest | undefined;
  saveSuccessful: boolean = false;
  policies: Policy[] = [];
  isFetchingPolicies: boolean = false;
  errorMessages: string[] = [];
  isSaveDisabled: boolean = false;

  harvestDetailsMessages: string[] = [];
  harvestDetailsMessageType: MessageType = 'success';
  harvestDetailsMessageTitle: string = '';

  @Input()
  set harvest(harvest: Harvest) {
    if (harvest !== null && harvest !== undefined) {
      this.saveSuccessful = false;
      this.policies = [];
      this.harvestOwner = harvest.harvestOwner!;
      if (this.sbisList !== undefined) { this.sbisList.reset(); }
      this.sbis = harvest.sbis ?? [];
      this._harvest = harvest;
      this.getPolicies();
      this.updateSaveDisabled()
      this.harvestDetailsMessages = [];
      this.harvestDetailsMessageType = 'success';
      this.harvestDetailsMessageTitle = '';
    }
  }

  @Output() savedHarvest = new EventEmitter<Harvest>();

  harvestOwner: HarvestOwner = {
    id: Guid.EMPTY,
    firstName: '',
    lastName: '',
    entityName: '',
    doingBusinessAs: '',
    phoneNumber: '',
    emailAddress: '',
    isEntity: false,
    tinSuffix: ''
  };

  sbis: SBI[] = [];

  constructor(
    private dialog: DialogService,
    private harvestOwnerService: HarvestOwnerService,
    private harvestService: HarvestService,
    private agentService: AgencyAgentService) { }

  ngOnInit(): void {
  }

  hasChanges(): boolean {
    let policiesHaveChanges = false;
    let harvestOwnerHasChanges = this.harvestOwnerDetails ? this.harvestOwnerDetails.touched : false;
    let sbisHaveChanges = this.sbisList ? this.sbisList.touched : false;
    if (this.policiesList && this.policiesList.length > 0) {
      policiesHaveChanges = this.policiesList.reduce((acc: boolean, curr) => acc || curr.touched, policiesHaveChanges);
    }
    return harvestOwnerHasChanges || sbisHaveChanges || policiesHaveChanges;
  }

  updateSaveDisabled(): void {
    let firstSBI = this.sbis[0];
    let harvestOwnerHasChanges = this.harvestOwnerDetails ? this.harvestOwnerDetails.touched : false;
    var validtype = false;

    if (harvestOwnerHasChanges) {
      var harvestOwner = this.harvestOwnerDetails.ownerType;
      if (harvestOwner === OwnerType.Individual) {
        validtype = (firstSBI?.firstName.trim() === '' || firstSBI?.lastName.trim() === '')
      }
      if (harvestOwner === OwnerType.Entity) {
        validtype = (firstSBI?.entityName.trim() === '')
      }
    }
    this.isSaveDisabled = validtype;
  }

  markAsClean(): void {
    if (this.policiesList && this.policiesList.length > 0) {
      this.policiesList.forEach(policy => policy.reset());
    }
  }

  setOwnerType(value: OwnerType) {
    var currentdata = this.harvestOwnerDetails.form.value;
    if (value === OwnerType.Individual) {
      let firstSBI = this.sbis[0];
      firstSBI.firstName = currentdata.firstName;
      firstSBI.lastName = currentdata.lastName;
      firstSBI.entityName = '';
      firstSBI = { ...firstSBI }
      this.sbisList.form.at(0)?.get('firstName')?.setValue('');
      this.sbisList.form.at(0)?.get('lastName')?.setValue('');
      this.updateFirstSBI(firstSBI);
    }
    if (value === OwnerType.Entity) {
      let firstSBI = this.sbis[0];
      firstSBI.firstName = '';
      firstSBI.lastName = '';
      firstSBI.entityName = currentdata.entityName;
      firstSBI = { ...firstSBI }
      this.sbisList.form.at(0)?.get('firstName')?.setValue('');
      this.sbisList.form.at(0)?.get('lastName')?.setValue('');
      this.updateFirstSBI(firstSBI);
    }
    this.updateSaveDisabled();
  }

  setOwnerTypeAndData(data: { ownerType: OwnerType, owner: HarvestOwner }) {

    if (data.ownerType === OwnerType.Individual) {
      let firstSBI = this.sbis[0];
      firstSBI.firstName = data.owner?.firstName ?? '';
      firstSBI.lastName = data.owner?.lastName ?? '';
      firstSBI.entityName = '';
      firstSBI = { ...firstSBI }
      this.sbisList.form.at(0)?.get('firstName')?.setValue('');
      this.sbisList.form.at(0)?.get('lastName')?.setValue('');
      this.updateFirstSBI(firstSBI);
    }
    else {
      let firstSBI = this.sbis[0];
      firstSBI.firstName = '';
      firstSBI.lastName = '';
      firstSBI.entityName = data.owner?.entityName ?? '';
      firstSBI = { ...firstSBI }
      this.sbisList.form.at(0)?.get('firstName')?.setValue('');
      this.sbisList.form.at(0)?.get('lastName')?.setValue('');
      this.updateFirstSBI(firstSBI);
    }
    this.updateSaveDisabled();
  }

  cancel() {
    if (this.sbisList.touched || this.harvestOwnerDetails.touched) {
      this.dialog.openUnsavedChangesDialog()
        .subscribe(value => {
          if (value) {
            this.sbisList.reset();
            this.savedHarvest.emit();
          }
        });
    } else {
      this.sbisList.reset();
      this.savedHarvest.emit();
    }
  }

  saveHarvestOwner() {
    this.harvestDetailsMessages = [];
    this.harvestOwnerDetails.markAsTouched();
    this.sbisList.markAsTouched();
    this.saveSuccessful = false;
    if (!this.harvestOwnerDetails.valid || !this.sbisList.valid) {
      this.harvestDetailsMessageTitle = '';
      this.harvestDetailsMessageType = 'alert';
      this.harvestDetailsMessages = ['Enter required fields'];
      return;
    }

    const harvestOwner = this.harvestOwnerDetails.value;
    harvestOwner.physicalAddress = this.harvestOwner.physicalAddress;
    harvestOwner.mailingAddress = this.harvestOwner.mailingAddress;
    forkJoin({
      harvestOwner: this.harvestOwnerService.updateHarvestOwner(harvestOwner),
      sbis: this.harvestService.updateSBIs(this._harvest!.id!, this.sbisList.value)
    })
      .subscribe((result: { harvestOwner: HarvestOwner, sbis: SBI[] }) => {
        this._harvest!.harvestOwner = result.harvestOwner;
        this._harvest!.sbis = result.sbis;

        this.savedHarvest.emit(this._harvest);
        this.harvestOwner = result.harvestOwner;
        this.sbisList.reset();
        this.harvestOwnerDetails.reset();
        this.sbis = result.sbis;
        this.harvestDetailsMessageTitle = 'Save successful';
        this.harvestDetailsMessageType = 'success';
        this.harvestDetailsMessages = ['Harvest details saved'];
        this.saveSuccessful = true;
      })
  }

  getPolicies() {
    this.isFetchingPolicies = true;
    this.agentService.getPolicies(this._harvest!.id!)
      .subscribe(result => {
        this.policies = result;
        this.isFetchingPolicies = false;
      },
        _ => {
          this.isFetchingPolicies = false;
          this.errorMessages = ['Error attempting to retrieve list of policies'];
        });
  }

  updateFirstSBI(updatedSBI: SBI) {
    let sbis = [...this.sbis]
    sbis.splice(0, 1);

    this.sbis = [updatedSBI, ...sbis]
    this.updateSaveDisabled();
  }
}
