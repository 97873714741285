import { Address } from "./address.model";

export interface HarvestOwner {
  id?: string;
  firstName: string;
  lastName: string;
  entityName: string;
  doingBusinessAs: string;
  emailAddress: string;
  phoneNumber: string;
  tinSuffix: string;
  physicalAddress?: Address;
  mailingAddress?: Address;
  isEntity: boolean;
}

export enum OwnerType {
  Individual,
  Entity
}