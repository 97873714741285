import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { RmaEntity } from 'src/app/models/rma.model';
import { RmaService } from 'src/app/services/rma.service';
import { StatesList } from 'src/app/models/states.model';
import { DocumentService } from 'src/app/services/document.service';
import { ServiceType } from 'src/app/models/type.model';
import { createMessageContent } from 'src/app/utility';
import { MessageContent } from 'src/app/messages/messages.component';

@Component({
  selector: 'hh-document-properties',
  templateUrl: './document-properties.component.html',
  styleUrls: ['./document-properties.component.scss']
})
export class DocumentPropertiesComponent implements OnInit {
  years: number[];
  states = StatesList;
  form: FormGroup;
  aips: RmaEntity[] = [];
  cropTypes: RmaEntity[] = [];
  documentTypes: ServiceType[] = [];
  @Output() onError = new EventEmitter<MessageContent>();
  constructor(private builder: FormBuilder, private rmaService: RmaService, private documentService: DocumentService) {
    const currentYear = new Date().getFullYear();
    this.years = [currentYear - 1, currentYear, currentYear + 1];
    this.form = builder.group({
      policyNumber: ['', Validators.required],
      approvedInsuranceProvider: ['', Validators.required],
      documentType: ['', Validators.required],
      year: ['', Validators.required],
      state: ['', Validators.required],
      cropTypes: this.builder.array([
        ['', Validators.required]
      ])
    })
  }

  ngOnInit(): void {
    forkJoin({
      aips: this.rmaService.getAIPs(),
      cropTypes: this.rmaService.getCropTypes(),
      documentTypes: this.documentService.getDocumentTypes()
    }).subscribe(res => {
      this.aips = res.aips;
      this.cropTypes = res.cropTypes;
      this.documentTypes = res.documentTypes;
    },error => {
      if (error.status) {
        const message = createMessageContent(error.status);
        this.onError.emit({title:  message?.title ?? '', messages: [message?.messages[0] ?? '']});
      }
      else {
        this.onError.emit({title:  '', messages: ['An unknown error occurred. Try refreshing this page, or navigate to the home page.']});
      }
    });
  }

  get cropTypesForm() {
    return this.form.get('cropTypes') as FormArray;
  }

  getCropList(index: number): RmaEntity[] {
    var types = this.cropTypes ?? [];
    var selectedTypes: string[] = [...this.cropTypesForm.value];
    selectedTypes.splice(index, 1);
    types = types.filter(x => !selectedTypes.includes(x.code));

    return types;
  }

  addCropType() {
    this.cropTypesForm.push(this.builder.control(''));
  }

  removeCropType() {
    var idx = this.cropTypesForm.controls.length - 1;
    this.cropTypesForm.removeAt(idx);
  }

  markAsTouched() {
    this.form.markAllAsTouched();
  }

  get touched(): boolean {
    return this.form.touched;
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get value(): any {
    let value = this.form.value;
    let prodReportType = this.documentTypes.filter(x => x.type == 'ProductionReport')[0];
    if (value.documentType === prodReportType!.id) {
      value.year = (parseInt(value.year, 10) - 1) + '';
    }
    return this.form.value;
  }
}
