import { Component, Input, OnInit } from '@angular/core';
import { SystemUser } from 'src/app/models/system-user.model';

@Component({
  selector: 'hh-user-document-reviews',
  templateUrl: './user-document-reviews.component.html',
  styleUrls: ['./user-document-reviews.component.scss']
})
export class UserDocumentReviewsComponent implements OnInit {
  @Input()
  set user(user: SystemUser | undefined) {
    
  }

  constructor() { }

  ngOnInit(): void {
  }

}
