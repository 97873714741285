import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { isFocusable } from '@testing-library/user-event/dist/utils';
import { filter } from 'rxjs/operators';
import { DocumentTag, UserDocument } from 'src/app/models/grower-document.model';
import { SystemUser } from 'src/app/models/system-user.model';
import { ServiceType } from 'src/app/models/type.model';
import { DocumentService } from 'src/app/services/document.service';
import { createMessageContent } from 'src/app/utility';

@Component({
  selector: 'hh-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: MatTableDataSource<UserDocument> = new MatTableDataSource<UserDocument>([]);
  data: UserDocument[] = [];
  successTitle = '';
  successText = '';
  errorTitle: string = '';
  errorMessages: string[] = [];
  loading = true;
  loadingDocumentStatusTypes = true;
  search = '';
  panelOpenState = false;
  documentStatusTypes!: ServiceType[];
  documentStatusTypesControl = new FormControl();
  documentStatusSelectedTypes: string[] = [];
  selectedRow!: UserDocument | null;
  selectedRowId: string = '';
  localStorageFilterKey: string = 'DocumentStatusFilters';
  localStorageSelectedRowKey: string = 'DocumentListSelectedRow';

  constructor(private router: Router, private route: ActivatedRoute, private documentService: DocumentService, private changeDetector : ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.loadInitialDocuments();
  }

  ngOnInit(): void {
    this.subscribeToParams();
  }

  subscribeToParams(): void {
    this.route.params.subscribe(params => {
      if (params['rejected'] && params['rejected'] !== '') {
        const rejectedDocument: SystemUser | null | undefined = JSON.parse(atob(params['rejected']));

        if (rejectedDocument) {
          this.successTitle = 'Successfully Rejected';
          this.successText = `${rejectedDocument.person.fullName}'s (${rejectedDocument.email}) document was successfully rejected.`;
        }
      }
      if (params['accepted'] && params['accepted'] !== '') {
        const acceptedDocument: SystemUser | null | undefined = JSON.parse(atob(params['accepted']));
        if (acceptedDocument) {
          this.successTitle = 'Successfully Created';
          this.successText = 'Harvest has been successfully created.';
        }
      }
    });
  }

  loadInitialDocuments(): void {
    this.documentService.getDocumentStatusTypes()
    .subscribe(documentStatusTypes => {
        this.loadingDocumentStatusTypes = false;
        this.documentStatusTypes = documentStatusTypes;
        let initialFilters = this.getLocalStorageValue(this.localStorageFilterKey);

        if(initialFilters && initialFilters.length > 0) {
          this.documentStatusTypesControl.setValue(initialFilters);
          this.getDocumentsByStatuses(initialFilters, false, this.getLocalStorageValue(this.localStorageSelectedRowKey));
        }
        else {
          const uploaded = this.documentStatusTypes.find(x => x.type.toLowerCase() === 'uploaded') || null;
          if (uploaded !== null) {
            this.documentStatusTypesControl.setValue([uploaded.id]);
            this.getDocumentsByStatuses([uploaded.id], false, this.getLocalStorageValue(this.localStorageSelectedRowKey));
          }
        }
      },
      error => {
        this.loadingDocumentStatusTypes = false;
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
    });
  }

  getDocumentsByStatuses(statusIds: string[], applyFilter: boolean = false, selectedRowId: string = '') {
    this.documentService.getDocumentsByStatus(statusIds)
    .subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: UserDocument, property: string) => {
        switch (property) {
          case 'uploadDate':
            return item.document.uploadDate;
          case 'fullName':
            return item.fullName.toLowerCase();
          case 'email':
            return item.email.toLowerCase();
          default: return (item as any)[property];
        }
      }
      this.dataSource.sort.active = 'uploadDate';
      this.dataSource.sort.direction = 'desc';
      this.dataSource.sort.sortChange.emit({ active: this.dataSource.sort.active, direction: this.sort.direction });
      this.data = result;
      this.loading = false;
      this.changeDetector.detectChanges();
      if(applyFilter) { this.filterDocuments(); }

      if(this.dataSource.data && this.dataSource.data.length > 0 && selectedRowId && selectedRowId !== '') {
        let resultRow = this.dataSource.data.find(x => x.document.id?.toLowerCase() === selectedRowId.toLowerCase());
        if(resultRow) {
          this.selectedRow = resultRow;
        }
      }
    }, error => {
      if (error.status) {
        const message = createMessageContent(error.status);
        this.errorTitle = message?.title ?? '';
        this.errorMessages = [message?.messages[0] ?? ''];
      }
      else {
        this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
      }
      this.loading = false;
      this.changeDetector.detectChanges();
    });
  }

  // Deprecated
  getDocuments() {
    this.documentService.getUploadedDocuments()
      .subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: UserDocument, property: string) => {
          switch (property) {
            case 'uploadDate':
              return item.document.uploadDate;
            case 'fullName':
              return item.fullName.toLowerCase();
            case 'email':
              return item.email.toLowerCase();
            default: return (item as any)[property];
          }
        }
        this.dataSource.sort.active = 'uploadDate';
        this.dataSource.sort.direction = 'desc';
        this.dataSource.sort.sortChange.emit({ active: this.dataSource.sort.active, direction: this.sort.direction });
        this.data = result;
        this.loading = false;
        this.changeDetector.detectChanges();
      }, error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
        this.loading = false;
        this.changeDetector.detectChanges();
      });
  }

  addDocument() {
    this.router.navigate(['documents', 'add']);
  }

  openDocumentFile(documentId: string) {
    this.documentService.getDocumentFile(documentId)
      .subscribe(result => {
        window.open(result, '_blank')
      }, error => {
        if (error.status) {
          const message = createMessageContent(error.status);
          this.errorTitle = message?.title ?? '';
          this.errorMessages = [message?.messages[0] ?? ''];
        }
        else {
          this.errorMessages = ['An unknown error occurred. Try refreshing this page, or navigate to the home page.'];
        }
      });
  }

  filterDocuments() {
    this.dataSource.filter = this.search;
  }

  processDocument(documentId: string) {
    this.router.navigate(['documents/process', documentId]);
  }

  manageDocumentTags(documentId: string) {
    this.router.navigate(['documents/tags', documentId]);
  }

  setDocumentStatusFilters(filters: MatSelectChange) {
    this.loading = true;
    this.dataSource.data = [];
    this.documentStatusSelectedTypes = filters.value;
    this.getDocumentsByStatuses(this.documentStatusSelectedTypes, this.checkSearchValue());
    this.setLocalStorageValue(this.localStorageFilterKey, filters.value);
  }

  checkSearchValue() {
    return (this.search !== null && this.search !== undefined && this.search !== '') ? true : false;
  }

  getLocalStorageValue(key: string) {
    let storageValue = localStorage.getItem(key)

    if(storageValue && storageValue !== '') {
      return JSON.parse(storageValue);
    }
    else {
      return null;
    }
  }

  setLocalStorageValue(key: string, value: string) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clearLocalStorageValue(key: string) {
    localStorage.removeItem(key);
  }

  highlightRow(row: UserDocument) {
    if(this.selectedRow === row) {
      this.selectedRow = null;
      this.clearLocalStorageValue(this.localStorageSelectedRowKey);
    }
    else {
      this.selectedRow = row;
      this.setLocalStorageValue(this.localStorageSelectedRowKey, row.document.id ?? '');
    }
  }

  getSelectedRowId() {
    if(this.selectedRow && this.selectedRow.document.id) {
      return this.selectedRow.document.id
    }
    else {
      return "";
    }
  }

  allowDocumentProcess(status: string) {
    switch (status) {
      case 'Uploaded':
        return true;
      case 'Accepted':
        return false;
      case 'Rejected':
        return false;
      default:
        return true;
    }
  }

  processTagDisplay(func: DocumentTag, tags: DocumentTag[]) {
    if(tags.length > 1) {
      return tags[tags.length - 1] == func ? func.documentTagType.description : func.documentTagType.description + ",";
    }
    else {
      return func.documentTagType.description;
    }
  }

  processTagTitle(tags: DocumentTag[]) {
    if(tags && tags.length > 0) {
      let title = "";

      tags.forEach(element => {
        title += (element.documentTagType.description + '\n');
      });

      return title;
    }
    else {
      return "";
    }
  }
}
